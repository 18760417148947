import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import {
  ErrorBoundary,
  formatMessageWithValues,
  Helmet,
  historyPush,
  withHistory,
  withModulesManager
} from "@openimis/fe-core";
import MedicalGratuitePage from "./MedicalGratuitePage";

class MedicalGratuiteOverviewPage extends Component {
  render() {
    const { history, modulesManager, medicalGratuiteId } = this.props;
    const actions = [
      {
        doIt: (e) => historyPush(modulesManager, history, "medical.GratuiteOverview", [medicalGratuiteId]),
        icon: <EditIcon />,
        onlyIfDirty: false,
      },
    ];
    return (
      <ErrorBoundary>
        <Helmet title={formatMessageWithValues(this.props.intl, "medical.gratuite", "overviewTitle")} />
        <MedicalGratuitePage {...this.props} readOnly={true} overview={true} actions={actions} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    medicalGratuiteId: props.match.params.medical_gratuite_id,
  };
};

export default withHistory(withModulesManager(connect(mapStateToProps)(injectIntl(MedicalGratuiteOverviewPage))));
