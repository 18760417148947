import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations, PublishedComponent, TextAreaInput, TextInput } from "@openimis/fe-core";
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { createConfig, SendMessage, fetchConfig } from "../actions";
import { makeStyles } from "@material-ui/styles";
import SaveIcon from "@material-ui/icons/SaveAlt";
const styles = makeStyles((theme) => ({
  page: theme.page,
  paper: theme.paper.paper,
  title: theme.paper.title,
}));

const Configs = (props) => {
  const { modulesManager } = props;
  const classes = styles();
  const { formatMessage } = useTranslations("profile.ChangePasswordPage", modulesManager);
  const dispatch = useDispatch();
  const [libelle, setLibelle] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [form, setForm] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await dispatch(fetchConfig());
      const fetchedLibelle = response.payload.data.configurations.edges[0]?.node?.libelle;
      setLibelle(fetchedLibelle);
    };
    fetchData();
  }, []);

  const onSubmit = async (e) => {
    dispatch(createConfig(modulesManager, formValues, "Config créer"));
  };
  const openDialog = () => {
    setIsOpen(true);
  };
  const Save = (e) => {
    if (form?.event?.target?.checked === true) {
      form.event = true;
    } else {
      form.event = false;
    }
    dispatch(SendMessage(modulesManager, form, "Message Envoyé"));
  };
  const close = () => {
    setIsOpen(false);
  }

  return (
    <div className={classes.page}>
      <Box padding="10px">
        <Paper className={classes.paper}>
          <Typography className={classes.title} variant="h6">
            {formatMessage("title.configuration")} ({libelle})
          </Typography>
          <Box padding="10px">
            <form onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid xs={4} item></Grid>
                <TextInput
                  module="admin"
                  required
                  type="number"
                  label={formatMessage("libelle.configuration")}
                  onChange={(libelle) => setFormValues({ ...formValues, libelle })}
                />
                <Grid item>
                  <Button type="submit" disabled={!formValues.libelle} color="primary" variant="contained">
                    <SaveIcon />
                    {formatMessage("submitButton")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
        <Button onClick={openDialog} color="primary" variant="contained">
          Envoyer message
        </Button>
      </Box>
      <Dialog className={classes.page} open={isOpen} fullWidth maxWidth="lg">
        <DialogTitle>Envoie de message</DialogTitle>
        <form onSubmit={Save}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} className={classes.item}>
                <TextAreaInput
                  module="admin"
                  required
                  label="Saisir le message"
                  onChange={(message) => setForm({ ...form, message })}
                />
              </Grid>
              <Grid item xs={10} className={classes.item}>
                <PublishedComponent
                  pubRef="admin.EnrolmentInsureePicker"
                  module="admin"
                  value={form.insuree}
                  label="Choisir le(s) Assurés"
                  onChange={(insuree) => setForm({ ...form, insuree })}
                />
              </Grid>
              <Grid item xs={1} className={classes.item}></Grid>
              <Grid item xs={1} className={classes.item}>
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={(event) => setForm({ ...form, event })} />}
                  label="Tous"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={close}>Annuler</Button>
            <Button
              type="submit"
              disabled={!(form.message && (form.insuree || form.event))}
              color="primary"
              variant="contained"
            >
              Envoyer
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default Configs;
