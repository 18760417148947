import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
  formatMessageWithValues,
  withModulesManager,
  withHistory,
  historyPush,
  ErrorBoundary,
  Helmet,
} from "@openimis/fe-core";
import MedicalDiagnosticForm from "../components/MedicalDiagnosticForm";
import { createMedicalDiagnostic, updateMedicalDiagnostic } from "../actions";
import { RIGHT_MEDICALGRATUITES_ADD, RIGHT_MEDICALGRATUITES_EDIT } from "../constants";

const styles = (theme) => ({
  page: theme.page,
});

class MedicalDiagnosticPage extends Component {
  add = () => {
    historyPush(this.props.modulesManager, this.props.history, "medical.medicalDiagnosticNew");
  };

  save = (medicalDiagnostic) => {
    if (!medicalDiagnostic.id) {
      this.props.createMedicalDiagnostic(
        this.props.modulesManager,
        medicalDiagnostic,
        formatMessageWithValues(this.props.intl, "medical.medicalDiagnostic", "createMedicalDiagnostic.mutationLabel"),
      );
    } else {
      this.props.updateMedicalDiagnostic(
        this.props.modulesManager,
        medicalDiagnostic,
        formatMessageWithValues(this.props.intl, "medical.diagnostic", "update.mutationLabel"),
      );
    }
  };

  render() {
    const { classes, rights, medicalDiagnosticId, overview, modulesManager, history } = this.props;
    // if (medicalDiagnosticId) return null;
    console.log('ici la ee');
    return (
      <div className={classes.page}>
        <Helmet title={formatMessageWithValues(this.props.intl, "medical.diagnostic", "diagnosticTitle")} />
        <ErrorBoundary>
          <MedicalDiagnosticForm
            overview={overview}
            medicalDiagnosticId={medicalDiagnosticId}
            back={(e) => historyPush(modulesManager, history, "medical.medicalDiagnostics")}
            add={!medicalDiagnosticId ? this.add : null}
            save={this.save}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  userId: props.match.params.user_id,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ createMedicalDiagnostic, updateMedicalDiagnostic }, dispatch);

export default withHistory(
  withModulesManager(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(MedicalDiagnosticPage)))),
  ),
);
