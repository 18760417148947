import React, { Component } from "react";
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { withModulesManager, AutoSuggestion } from "@openimis/fe-core";

const styles = (theme) => ({
  textField: {
    width: "100%",
  },
});

class AnneePicker extends Component {
  constructor(props) {
    super(props);
    this.selectThreshold = props.modulesManager.getConf("fe-location", "DistrictPicker.selectThreshold", 10);
    // Items: List of years
    this.state = {
      items: [
        { label: "2018", value: "2018" },
        { label: "2019", value: "2019" },
        { label: "2020", value: "2020" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "2024", value: "2024" },
      ],
    };
  }

  onSuggestionSelected = (v) => {
    // if (v && this.props.value !== v) this.props.selectDistrictLocation(v);
    // this.props.onChange(v, v.label); // Use label instead of locationLabel
  };

  componentWillUnmount() {
  }

  render() {
    const {
      reset,
      value,
      readOnly = false,
      required = true,
    } = this.props;

    return (
      <AutoSuggestion
        module="location"
        items={this.state.items} // Use state items for years
        label="Année"
        lookup={(item) => item.label} // Directly use label
        getSuggestionValue={(item) => item.label} // Use label as value
        renderSuggestion={(item) => <span>{item.label}</span>} // Render label
        onSuggestionSelected={this.onSuggestionSelected}
        onClear={this.onSuggestionSelected}
        value={value}
        reset={reset}
        readOnly={readOnly}
        required={required}
        selectThreshold={this.selectThreshold}
      />
    );
  }
}



export default withModulesManager(
  connect(null)(injectIntl(withTheme(withStyles(styles)(AnneePicker)))),
);
