import React, { useState } from "react";
import { Autocomplete, toISODate, useGraphqlQuery, useTranslations } from "@openimis/fe-core";

const GenrePicker = (props) => {
  const {
    onChange,
    readOnly,
    required,
    withLabel,
    value,
    label,
    filterOptions,
    filterSelectedOptions,
    placeholder,
    withPlaceholder = false,
    multiple,
  } = props;
  const [searchString, setSearchString] = useState(null);
  const { formatMessage } = useTranslations("medical");

  const { isLoading, data, error } = useGraphqlQuery(
    `query {
        insureeGenders{
          code
          gender
          altLanguage
      }
    }`,
  );

  return (
    <Autocomplete
      multiple={multiple}
      required={required}
      placeholder={placeholder ?? formatMessage("GenrePicker.placehoder")}
      label={label ?? formatMessage("insuree.gender")}
      error={error}
      withLabel={withLabel}
      withPlaceholder={withPlaceholder}
      readOnly={readOnly}
      options={data?.insureeGenders.map((insureeGenders) => insureeGenders) ?? []}
      isLoading={isLoading}
      value={value ?? null}
      getOptionLabel={(option) => `${option.altLanguage}`}
      getOptionSelected={(option, value) => option?.code === value?.code}
      onChange={onChange}
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions}
      onInputChange={setSearchString}
    />
  );
};

export default GenrePicker;