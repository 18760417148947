import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Paper, Typography, Divider } from "@material-ui/core";

import { TextInput, journalize, FormattedMessage } from "@openimis/fe-core";
import { createCategory } from "../actions";

const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});


class AddTicketPage extends Component {

    state = {
        state_edited: {}
    }

    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevState.state_edited !== this.state.state_edited) {
            this.props.onEditedChanged(this.state.state_edited)
            // console.log("Mise à jour : Le state 'value' a changé.");
            // Exécutez une action ici, comme une requête ou une mise à jour.
        }
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }

    }

    save = e => {
        console.log('save ici');

        this.props.createCategory(this.state.state_edited, `Created Category`)

    }

    updateAttribute = (k, v) => {
        this.setState((state) => ({
            state_edited: { ...state.state_edited, [k]: v }
        }))
    }

    render() {
        const {
            intl, classes,
            edited,
            onEditedChanged,
            titletwo = "Category.DescriptionOfEvents",
            actions
        } = this.props;

        const {
            state_edited
        } = this.state;
        return (
            <div className={classes.page}>
                {/* <Grid container>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container className={classes.tableTitle}>
                                <Grid item xs={8} className={classes.tableTitle}>
                                    <Typography>
                                        <FormattedMessage module="grievance" id={titleone} values={titleParams} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.tableTitle}>
                                    <PublishedComponent
                                        pubRef="insuree.InsureePicker"
                                        value={state_edited.insuree}
                                        label="Complainant"
                                        onChange={(v) => this.updateAttribute("insuree", v)}
                                        required={true}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container className={classes.item}>
                                <Grid item xs={4} className={classes.item}>
                                    <TextInput
                                        module="grievance" label="ticket.name"
                                        value={!!state_edited && !!state_edited.insuree ? state_edited.insuree.otherNames  +" "+ state_edited.insuree.lastName : ""}
                                        onChange={v => this.updateAttribute("name", v)}
                                        required={false} 
                                        readOnly={true}/>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                    <TextInput
                                        module="grievance" label="ticket.phone"
                                        value={!!state_edited && !!state_edited.insuree ? state_edited.insuree.phone : ""}
                                        onChange={v => this.updateAttribute("phone", v)}
                                        required={false} 
                                        readOnly={true}/>
                                </Grid>
                                <Grid item xs={4} className={classes.item}>
                                    <TextInput
                                        module="grievance" label="ticket.email"
                                        value={!!state_edited && !!state_edited.insuree ? state_edited.insuree.email : ""}
                                        onChange={v => this.updateAttribute("email", v)}
                                        required={false} 
                                        readOnly={true}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid> */}

                <Grid container>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container className={classes.tableTitle}>
                                <Grid item xs={12} className={classes.tableTitle}>
                                    <Typography>
                                        <FormattedMessage module="grievance" id={titletwo} values="" />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container className={classes.item}>

                                <Grid item xs={6} className={classes.item}>
                                    <TextInput
                                        module="grievance" label="category.categoryTitle"
                                        value={state_edited.categoryTitle}
                                        onChange={v => this.updateAttribute("categoryTitle", v)}
                                        required={false} />
                                </Grid>
                                <Grid item xs={6} className={classes.item}>
                                    <TextInput
                                        label="category.slug"
                                        value={state_edited.slug}
                                        onChange={v => this.updateAttribute("slug", v)}
                                        required={false} />
                                </Grid>
                                <Grid item xs={11} className={classes.item}>
                                </Grid>
                                {/* <Grid item xs={1} className={classes.item}>
                                    <IconButton variant="contained" component="label" color="primary" onClick={this.save}>
                                        <Save />
                                    </IconButton>
                                </Grid> */}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    submittingMutation: state.grievance.submittingMutation,
    mutation: state.grievance.mutation,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createCategory, journalize }, dispatch)
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddTicketPage)));