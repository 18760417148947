import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { fetchInsureeAlls } from "../actions";
import { formatMessage, AutoSuggestion, ProgressOrError, withModulesManager, decodeId } from "@openimis/fe-core";

const styles = (theme) => ({
  label: {
    color: theme.palette.primary.main,
  },
});

class InsureeAll extends Component {
  constructor(props) {
    super(props);
    this.selectThreshold = props.modulesManager.getConf("fe-insuree", "InsureeOfficer.selectThreshold", 10);
  }

  componentDidMount() {
    if (!this.props.fetchingInsureeAlls) {
      // prevent loading multiple times the cache when component is
      // several times on tha page
      setTimeout(() => {
        !this.props.fetchingInsureeAlls && this.props.fetchInsureeAlls(this.props.modulesManager);
      }, Math.floor(Math.random() * 300));
    }
  }

  formatSuggestion = (a) => (!a ? "" : `${a.chfId} ${a.lastName} ${a.otherName || ""}`);

  onSuggestionSelected = (v) => this.props.onChange(v, this.formatSuggestion(v));

  render() {
    const {
      intl,
      value,
      reset,
      insureeAlls,
      fetchingInsureeAlls,
      fetchedInsureeAlls,
      errorInsureeAlls,
      withLabel = true,
      label,
      readOnly = false,
      required = false,
      withNull = false,
      nullLabel = null,
    } = this.props;
    let v = insureeAlls ? insureeAlls.filter((o) => parseInt(decodeId(o.id)) === value) : [];
    v = v.length ? v[0] : null;
    return (
      <Fragment>
        <ProgressOrError progress={fetchingInsureeAlls} error={errorInsureeAlls} />
          <AutoSuggestion
            module="insuree"
            items={insureeAlls}
            label={!!withLabel && (label || formatMessage(intl, "insuree", "InsureeOfficer.label"))}
            getSuggestions={this.insureeAlls}
            getSuggestionValue={this.formatSuggestion}
            onSuggestionSelected={this.onSuggestionSelected}
            value={v}
            reset={reset}
            readOnly={readOnly}
            required={required}
            selectThreshold={this.selectThreshold}
            withNull={withNull}
            nullLabel={nullLabel || formatMessage(intl, "insuree", "insuree.InsureeOfficer.null")}
          />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  insureeAlls: state.insuree.insureeAlls,
  fetchingInsureeAlls: state.insuree.fetchingInsureeAlls,
  fetchedInsureeAlls: state.insuree.fetchedInsureeAlls,
  errorInsureeAlls: state.insuree.errorInsureeAlls,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchInsureeAlls }, dispatch);
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(InsureeAll)))),
);
