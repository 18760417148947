import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles, withTheme } from "@material-ui/core/styles";
import ReplayIcon from "@material-ui/icons/Replay";

import {
  coreConfirm,
  Helmet,
  ErrorBoundary,
  Form,
  formatMessageWithValues,
  historyPush,
  journalize,
  parseData,
  ProgressOrError,
  withHistory,
  withModulesManager,
} from "@openimis/fe-core";
import {
  createMedicalDiagnostic,
  fetchMedicalDiagnostic,
  fetchMedicalDiagnosticMutation,
  newMedicalDiagnostic,
  clearDiagnosticForm,
} from "../actions";
import { DIAGNOSTIC_CODE_MAX_LENGTH } from "../constants";
import MedicalDiagnosticMasterPanel from "./MedicalDiagnosticMasterPanel";

const styles = (theme) => ({
  lockedPage: theme.page.locked,
});

class MedicalDiagnosticForm extends Component {
  state = {
    lockNew: false,
    reset: 0,
    medicalDiagnostic: null,
    newMedicalDiagnostic: true,
    confirmedAction: null,
  };

  // newMedicalDiagnostic() {
  //   return ;
  // }

  componentDidMount() {    
    if (this.props.medicalDiagnosticId) {
      this.setState(
        (state, props) => ({ medicalDiagnosticId: props.medicalDiagnosticId }),
        (e) => this.props.fetchMedicalDiagnostic(this.props.modulesManager, this.props.medicalDiagnosticId),
      );
    }
    if (this.props.id) {
      this.setState((state, props) => ({
        medicalDiagnostic: {
          ...this.newMedicalDiagnostic(),
          id: props.id,
        },
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.fetchedMedicalDiagnostic && !!this.props.fetchedMedicalDiagnostic) {
      const { medicalDiagnostic } = this.props;
      this.setState({
        medicalDiagnostic,
        medicalDiagnosticId: medicalDiagnostic.id,
        lockNew: false,
        newMedicalDiagnostic: false,
      });
    } else if (prevProps.medicalDiagnosticId && !this.props.medicalDiagnosticId) {
      this.setState({
        medicalDiagnostic: this.newMedicalDiagnostic(),
        newMedicalDiagnostic: true,
        lockNew: false,
        medicalDiagnosticId: null,
      });
    } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
      
      
      this.props.journalize(this.props.mutation);
      this.setState((state, props) => ({
        medicalDiagnostic: {
          ...state.medicalDiagnostic,
        },
      }));
    } else if (prevProps.confirmed !== this.props.confirmed && !!this.props.confirmed && !!this.state.confirmedAction) {
      this.state.confirmedAction();
    }
    
  }

  componentWillUnmount = () => {
    this.props.clearDiagnosticForm();
  };

  add = () => {
    this.setState(  
      (state) => ({
        medicalDiagnostic: this.newMedicalDiagnostic(),
        newMedicalDiagnostic: true,
        lockNew: false,
        reset: state.reset + 1,
      }),
      (e) => {
        this.props.add();
        this.forceUpdate();
      },
    );
  };

  reload = () => {
    const { medicalDiagnosticId } = this.props.mutation;
    if ( !medicalDiagnosticId) {
      this.props.fetchMedicalDiagnosticMutation(this.props.modulesManager).then((res) => {
        console.log('reload',res.payload.data.mutationLogs);
        
        const mutationLogs = parseData(res.payload.data.mutationLogs);
        if (
          mutationLogs &&
          mutationLogs[0] &&
          mutationLogs[0].medicalDiagnostics &&
          mutationLogs[0].medicalDiagnostics[0] &&
          mutationLogs[0].medicalDiagnostics[0].coreUser
        ) {
          const { id } = parseData(res.payload.data.mutationLogs)[0].users[0].coreUser;
          if (id) {
            historyPush(this.props.modulesManager, this.props.history, "medical.medicalDiagnosticOverview", [id]);
          }
        }
      });
    } else {
      this.props.fetchMedicalDiagnostic(this.props.modulesManager, medicalDiagnosticId);
    }
  };

  canSave = () =>
    this.state.medicalDiagnostic &&
    this.state.medicalDiagnostic.code &&
    this.state.medicalDiagnostic.code.length <= DIAGNOSTIC_CODE_MAX_LENGTH &&
    this.state.medicalDiagnostic.name &&
    this.props.isDiagnosticValid;

  save = (medicalDiagnostic) => { 
    console.log('donnesave',medicalDiagnostic);
        
    this.setState(
      { lockNew: !medicalDiagnostic.id }, // avoid duplicates
      (e) => this.props.save(medicalDiagnostic),
    );
  };

  onEditedChanged = (medicalDiagnostic) => {
    this.setState({ medicalDiagnostic, newMedicalDiagnostic: false });
  };

  onActionToConfirm = (title, message, confirmedAction) => {
    this.setState({ confirmedAction }, this.props.coreConfirm(title, message));
  };

  render() {
    const {
      classes,
      rights,
      medicalDiagnosticId,
      fetchingMedicalDiagnostic,
      fetchedMedicalDiagnostic,
      errorMedicalDiagnostic,
      overview = false,
      readOnly = false,
      add,
      save,
      back,
    } = this.props;
    
    const { medicalDiagnostic, reset, lockNew } = this.state;
    // if (!rights.includes(RIGHT_MEDICALDIAGNOSTICS)) return null;
    const actions = [
      {
        doIt: this.reload,
        icon: <ReplayIcon />,
        onlyIfDirty: !readOnly,
      },
    ];
    return (
      <div className={lockNew ? classes.lockedPage : null}>
        <Helmet title={formatMessageWithValues(this.props.intl, "medical.diagnostic", "MedicalDiagnosticOverview.title")} />
        <ProgressOrError progress={fetchingMedicalDiagnostic} error={errorMedicalDiagnostic} />
        <ErrorBoundary>
          {((!!fetchedMedicalDiagnostic && !!medicalDiagnostic && medicalDiagnostic.id === medicalDiagnosticId) ||
            !medicalDiagnosticId) && (
            <Form
              module="medicalDiagnostic"
              title={
                this.state.newMedicalDiagnostic
                  ? "medical.diagnostic.MedicalDiagnosticOverview.newTitle"
                  : "medical.diagnostic.MedicalDiagnosticOverview.title"
              }
              edited_id={medicalDiagnosticId}
              edited={medicalDiagnostic}
              reset={reset}
              back={back}
              add={!!add && !this.state.newMedicalDiagnostic ? this.add : null}
              readOnly={readOnly || lockNew || (!!medicalDiagnostic && !!medicalDiagnostic.validityTo)}
              actions={actions}
              overview={overview}
              HeadPanel={MedicalDiagnosticMasterPanel}
              medicalDiagnostic={medicalDiagnostic}
              onEditedChanged={this.onEditedChanged}
              canSave={this.canSave}
              save={save ? this.save : null}
              openDirty={save}
              onActionToConfirm={this.onActionToConfirm}
            />
          )}
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  fetchingMedicalDiagnostic: state.medical.fetchingMedicalDiagnostic,
  errorMedicalDiagnostic: state.medical.errorMedicalDiagnostic,
  fetchedMedicalDiagnostic: state.medical.fetchedMedicalDiagnostic,
  submittingMutation: state.medical.submittingMutation,
  mutation: state.medical.mutation,
  medicalDiagnostic: state.medical.medicalDiagnostic,
  confirmed: state.core.confirmed,
  isDiagnosticValid: state.medical?.validationFields?.medicalDiagnostic?.isValid,
  state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearDiagnosticForm,
      fetchMedicalDiagnostic,
      newMedicalDiagnostic,
      createMedicalDiagnostic,
      fetchMedicalDiagnosticMutation,
      journalize,
      coreConfirm,
    },
    dispatch,
  );

export default withHistory(
  withModulesManager(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(MedicalDiagnosticForm)))),
  ),
);
