import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import {
  AmountInput,
  ErrorBoundary,
  FormPanel,
  PublishedComponent,
  TextInput,
  ValidatedTextInput,
  withHistory,
  withModulesManager,
} from "@openimis/fe-core";
import { medicalDiagnosticsValidationCheck, medicalDiagnosticsValidationClear, medicalDiagnosticsSetValid } from "../actions";
import { DIAGNOSTIC_CODE_MAX_LENGTH } from "../constants";

const styles = (theme) => ({
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class MedicalDiagnosticMasterPanel extends FormPanel {
  shouldValidate = (inputValue) => {
    const { savedDiagnosticCode } = this.props;    
    const shouldValidate = inputValue !== savedDiagnosticCode;
    return shouldValidate;
  }
  render() {
    const { classes, edited, readOnly, isDiagnosticValid, isDiagnosticValidating, diagnosticValidationError} = this.props;
    return (
      <ErrorBoundary>
        <Grid container className={classes.item}>
          <Grid item xs={4} className={classes.item}>
            <ValidatedTextInput
              action={medicalDiagnosticsValidationCheck}
              clearAction={medicalDiagnosticsValidationClear}
              setValidAction={medicalDiagnosticsSetValid}
              itemQueryIdentifier="diagnosticCode"
              isValid={isDiagnosticValid}
              isValidating={isDiagnosticValidating}
              validationError={diagnosticValidationError}
              shouldValidate={this.shouldValidate}
              codeTakenLabel="medical.codeTaken"
              onChange={(code) => this.updateAttribute("code", code)}
              inputProps={{ maxLength: DIAGNOSTIC_CODE_MAX_LENGTH }}
              required={true}
              module="admin"
              label="medical.diagnostic.code"
              readOnly={readOnly}
              value={edited ? edited.code : ""}
            />
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <TextInput
              module="admin"
              label="medical.diagnostic.name"
              required
              readOnly={readOnly}
              value={edited && edited.name ? edited.name : ""}
              onChange={(name) => this.updateAttributes({ name })}
            />
          </Grid>
          <Grid item xs={4} className={classes.item}>
              <PublishedComponent
                pubRef="location.HealthFacilityLevelPicker"
                value={!!edited && !!edited.niveau ? edited.niveau : null}
                readOnly={readOnly}
                withNull={false}
                onChange={(p) => this.updateAttributes({"niveau":p} )}
              />
          </Grid>
        </Grid>
        <Grid container className={classes.item}>
          
        </Grid>
        {/* <Grid container className={classes.item}>
        
      
        </Grid> */}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  isDiagnosticValid: state.medical?.validationFields?.medicalDiagnostic?.isValid,
  isDiagnosticValidating: state.medical?.validationFields?.medicalDiagnostic?.isValidating,
  diagnosticValidationError: state.medical?.validationFields?.medicalDiagnostic?.validationError,
  savedDiagnosticCode: state.medical?.medicalDiagnostic?.code,
});

export default injectIntl(
  withModulesManager(withHistory(connect(mapStateToProps)(withTheme(withStyles(styles)(MedicalDiagnosticMasterPanel))))),
);
