import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
  formatMessageWithValues,
  withModulesManager,
  withHistory,
  historyPush,
  ErrorBoundary,
  Helmet,
} from "@openimis/fe-core";
import MedicalGratuiteForm from "../components/MedicalGratuiteForm";
import { createMedicalGratuite, updateMedicalGratuite } from "../actions";
import { RIGHT_MEDICALGRATUITES_ADD, RIGHT_MEDICALGRATUITES_EDIT } from "../constants";

const styles = (theme) => ({
  page: theme.page,
});

class MedicalGratuitePage extends Component {
  add = () => {
    historyPush(this.props.modulesManager, this.props.history, "medical.medicalGratuiteNew");
  };

  save = (medicalGratuite) => {
    if (!medicalGratuite.id) {
      this.props.createMedicalGratuite(
        this.props.modulesManager,
        medicalGratuite,
        formatMessageWithValues(this.props.intl, "medical.medicalGratuite", "createMedicalGratuite.mutationLabel"),
      );
    } else {
      this.props.updateMedicalGratuite(
        this.props.modulesManager,
        medicalGratuite,
        formatMessageWithValues(this.props.intl, "medical.gratuite", "update.mutationLabel"),
      );
    }
  };

  render() {
    const { classes, rights, medicalGratuiteId, overview, modulesManager, history } = this.props;
    // if (medicalGratuiteId) return null;
    return (
      <div className={classes.page}>
        <Helmet title={formatMessageWithValues(this.props.intl, "medical.gratuite", "gratuiteTitle")} />
        <ErrorBoundary>
          <MedicalGratuiteForm
            overview={overview}
            medicalGratuiteId={medicalGratuiteId}
            back={(e) => historyPush(modulesManager, history, "medical.medicalGratuites")}
            add={!medicalGratuiteId ? this.add : null}
            save={this.save}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  userId: props.match.params.user_id,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ createMedicalGratuite, updateMedicalGratuite }, dispatch);

export default withHistory(
  withModulesManager(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(MedicalGratuitePage)))),
  ),
);
