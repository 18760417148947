import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { HEALTH_FACILITY_LEGAL_TRIMESTRE} from "../constants";

class TrimestrePicker extends Component {
  render() {
    return (
      <ConstantBasedPicker
        module="location"
        label="healthFacilityLegalTrimestre"
        constants={HEALTH_FACILITY_LEGAL_TRIMESTRE}
        {...this.props}
      />
    );
  }
}

export default TrimestrePicker;
