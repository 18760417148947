import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withTheme, withStyles } from "@material-ui/core/styles";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, } from "@material-ui/core";

import { FormattedMessage } from "@openimis/fe-core";

const styles = (theme) => ({
  primaryButton: theme.dialog.primaryButton,
  secondaryButton: theme.dialog.secondaryButton,
});

class InfoMedicalGratuiteDialog extends Component {
  render() {
    const { classes, medicalGratuite, onCancel, onConfirm } = this.props;
    console.log('ici',);
    
    const gratuite = !!medicalGratuite ? medicalGratuite.name : "";    
    return (
      <Dialog open={!!medicalGratuite} onClose={onCancel}  fullWidth maxWidth="lg" >
        <DialogTitle>
          <FormattedMessage module="admin" id={`Liste des Soins et des Produits pour la Gratuité ${gratuite}`} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          <TableContainer component={Paper} className={classes.container}>
                <Table stickyHeader size="small" arial-label="Les services">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          "font-weight": "bold",
                          "text-align": "center",
                        }}
                      >
                        les Soins
                      </TableCell>
                      <TableCell
                        style={{
                          "font-weight": "bold",
                          "text-align": "center",
                        }}
                      >
                        Les Produits
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {Array.from({ length: Math.max(medicalGratuite?.services?.length || 0, medicalGratuite?.items?.length || 0) }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {medicalGratuite?.services?.[index]?.service?.name || ''}
                      </TableCell>
                      <TableCell align="center">
                        {medicalGratuite?.items?.[index]?.item?.name || ''}
                      </TableCell>
                    </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} className={classes.secondaryButton}>
            <FormattedMessage module="core" id="Fermer" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(withTheme(withStyles(styles)(InfoMedicalGratuiteDialog)));
