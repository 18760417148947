import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import {
  ErrorBoundary,
  formatMessageWithValues,
  Helmet,
  historyPush,
  withHistory,
  withModulesManager
} from "@openimis/fe-core";
import MedicalDiagnosticPage from "./MedicalDiagnosticPage";

class MedicalDiagnosticOverviewPage extends Component {
  render() {
    const { history, modulesManager, medicalDiagnosticId } = this.props;
    const actions = [
      {
        doIt: (e) => historyPush(modulesManager, history, "medical.DiagnosticOverview", [medicalDiagnosticId]),
        icon: <EditIcon />,
        onlyIfDirty: false,
      },
    ];
    return (
      <ErrorBoundary>
        <Helmet title={formatMessageWithValues(this.props.intl, "medical.diagnostic", "overviewTitle")} />
        <MedicalDiagnosticPage {...this.props} readOnly={true} overview={true} actions={actions} />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    medicalDiagnosticId: props.match.params.medical_diagnostic_id,
  };
};

export default withHistory(withModulesManager(connect(mapStateToProps)(injectIntl(MedicalDiagnosticOverviewPage))));
