import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { IconButton, Tooltip } from "@material-ui/core";
import { Tab as TabIcon, Delete as DeleteIcon } from "@material-ui/icons";
import {
  withModulesManager,
  formatMessageWithValues,
  formatMessage,
  Searcher,
  journalize,
  formatDateFromISO,
} from "@openimis/fe-core";

import { fetchCategorySummaries, resolveCategory, deleteTicketCategory } from "../actions";

import DeleteCategoryDialog from "./DeleteCategory";
import CategoryFilter from "./CategoryFilter";


class CategorySearcher extends Component {
  state = {
    deleteCategory: null,
    confirmedAction: null,
    reset: 0,
    params: {},
  };

  constructor(props) {
    super(props);
    this.locationLevels = 4;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
      this.setState({ reset: this.state.reset + 1 });
    } else if (!prevProps.confirmed && this.props.confirmed) {
      this.state.confirmedAction();
    }
  }

  fetch = (params) => {
    this.setState({ params });
    this.props.fetchCategorySummaries(this.props.modulesManager, params);
  };

  rowIdentifier = (r) => r.id;

  filtersToQueryParams = (state) => {
    const prms = Object.keys(state.filters)
      .filter((contrib) => !!state.filters[contrib].filter)
      .map((contrib) => state.filters[contrib].filter);
    if (!state.beforeCursor && !state.afterCursor) {
      prms.push(`first: ${state.pageSize}`);
    }
    if (state.afterCursor) {
      prms.push(`after: "${state.afterCursor}"`);
      prms.push(`first: ${state.pageSize}`);
    }
    if (state.beforeCursor) {
      prms.push(`before: "${state.beforeCursor}"`);
      prms.push(`last: ${state.pageSize}`);
    }
    // if (state.orderBy) {
    //   prms.push(`orderBy: ["${state.orderBy}"]`);
    // }
    return prms;
  };
  resolveCategory = e => {
    this.props.resolveCategory(this.props.edited_id)
  }
  headers = () => {
    const h = [
      "category.categoryTitle",
      "category.slug",
      "category.validFrom",
    ];
    return h;
  };

  sorts = () => [
    ["categoryTitle", true],
    ["slug", true],
    ["validityFrom", false],
  ];

  deleteCategory = () => {
    console.log('dell');
    const category = this.state.deleteCategory;
    this.setState({ deleteCategory: null }, async (e) => {
      this.props.deleteTicketCategory(category, `Categorie ${category.uuid} a été supprimé avec succès`)
    });
  };

  confirmDelete = (deletedCategory) => {
    console.log('cost', deletedCategory);
    this.setState({ deleteCategory: deletedCategory });
  };

  deleteAction = (i) => {
    console.log('dest', i);

    return !!i.validityTo ? null : (
      <Tooltip title={formatMessage(this.props.intl, "grievance", "categorySummaries.deleteCategory.tooltip")}>
        <IconButton onClick={() => this.confirmDelete(i)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  };
  itemFormatters = () => {
    const formatters = [
      (ms) => ms.categoryTitle,
      (ms) => ms.slug,
      (ms) => formatDateFromISO(this.props.modulesManager, this.props.intl, ms.validityFrom),
      (ms) => (
        <Tooltip title={formatMessage(this.props.intl, "grievance", "openNewTab")}>
          <IconButton onClick={(e) => this.props.onDoubleClick(ms, true)}>
            <TabIcon />
          </IconButton>
        </Tooltip>
      ),
    ];
    formatters.push(this.deleteAction);
    // if (this.props.rights.includes(RIGHT_MEDICALSERVICES_DELETE)) {
    //   formatters.push(this.deleteAction);
    // }
    return formatters;
  };

  rowDisabled = (selection, i) => !!i.validityTo;

  // rowLocked = (selection, i) => !!i.clientMutationId;

  render() {
    const { intl,
      category, categorys, categorysPageInfo, fetchingCategorys, fetchedCategorys, errorCategorys,
      filterPaneContributionsKey, cacheFiltersKey, onDoubleClick, rights, readOnly, classes
    } = this.props;

    let count = categorysPageInfo.totalCount;
    return (
      <>


        <DeleteCategoryDialog
          category={this.state.deleteCategory}
          onConfirm={this.deleteCategory}
          onCancel={(e) => this.setState({ deleteCategory: null })}
        />
        <Searcher
          module="grievance"
          cacheFiltersKey={cacheFiltersKey}
          FilterPane={CategoryFilter}
          items={categorys}
          itemsPageInfo={categorysPageInfo}
          fetchingItems={fetchingCategorys}
          fetchedItems={fetchedCategorys}
          errorItems={errorCategorys}
          tableTitle={formatMessageWithValues(intl, "grievance", "categorySummaries", {
            count,
          })}
          fetch={this.fetch}
          rowIdentifier={this.rowIdentifier}
          filtersToQueryParams={this.filtersToQueryParams}
          defaultOrderBy="categoryTitle"
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          sorts={this.sorts}
          rowDisabled={this.rowDisabled}
          // rowLocked={this.rowLocked}
          onDoubleClick={(c) => onDoubleClick(c)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  categorys: state.grievance.categorys,
  categorysPageInfo: state.grievance.categorysPageInfo,
  fetchingCategorys: state.grievance.fetchingCategorys,
  fetchedCategorys: state.grievance.fetchedCategorys,
  errorCategorys: state.grievance.errorCategorys,
  submittingMutation: state.grievance.submittingMutation,
  mutation: state.grievance.mutation,
  confirmed: state.core.confirmed,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchCategorySummaries, resolveCategory, deleteTicketCategory, journalize }, dispatch);

export default withModulesManager(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CategorySearcher)));
