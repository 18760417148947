import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import ReplayIcon from "@material-ui/icons/Replay";
import {
    formatMessageWithValues, withModulesManager, journalize,
    Form, ProgressOrError
} from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { fetchCategory } from "../actions";
import EditCategoryPage from "../pages/EditCategoryPage.js";
import AddCategoryPage from "../pages/AddCategoryPage.js";

const styles = theme => ({
    page: theme.page,
});

class CategoryForm extends Component {

    state = {
        lockNew: false,
        reset: 0,
        category_uuid: null,
        category: this._newCategory(),
        newCategory: true,
    }

    _newCategory() {
        let category = {};
        return category;
    }

    componentDidMount() {
        if (this.props.category_uuid) {
            this.setState((state, props) => ({ category_uuid: props.category_uuid }))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.category.categoryTitle !== this.state.category.categoryTitle) {
            document.title = formatMessageWithValues(this.props.intl, "grievance", "category.title.bar")
        }
        if (prevProps.fetchedCategory !== this.props.fetchedCategory
            && !!this.props.fetchedCategory
            && !!this.props.category) {
            this.setState((state, props) => ({
                category: { ...props.category },
                category_uuid: props.category.uuid,
                lockNew: false,
                newCategory: false,
            }));
        } else if (prevState.category_uuid !== this.state.category_uuid) {
            console.log("testdddd", this.state.category_uuid, prevState.category_uuid);

            this.props.fetchCategory(
                this.props.modulesManager,
                this.state.category_uuid,
                null
            )
        } else if (prevProps.category_uuid && !this.props.category_uuid) {
            this.setState({ category: this._newCategory(), lockNew: false, category_uuid: null });
        } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
            this.setState((state) => ({ reset: state.reset + 1 }));
        }
    }


    _add = () => {
        this.setState((state) => ({
            category: this._newCategory(),
            newCategory: true,
            lockNew: false,
            reset: state.reset + 1,
        }),
            e => {
                this.props.add();
                this.forceUpdate();
            }
        )
    }

    // reload = () => {
    //     this.props.fetchCategoryAttachments(
    //         this.props.modulesManager,
    //         this.state.category_uuid,
    //         this.state.category.code,

    //     );
    // }

    canSave = () => {
        if (!this.state.category.categoryTitle) return false;
        if (!this.state.category.slug) return false;
        return true;
    }

    _save = (category) => {
        console.log("save", category);

        this.setState(
            { lockNew: !category.uuid },
            e => this.props.save(category))
    }

    onEditedChanged = (category) => {
        console.log(category);

        this.setState({ category, newCategory: false })
    }

    render() {
        const {
            fetchingCategory,
            fetchedCategory,
            errorCategory,
            add, save, back
        } = this.props;

        const {
            lockNew,
            newCategory,
            reset,
            update,
            overview,
            category_uuid,
            category
        } = this.state;

        let readOnly = lockNew || !!category.validityTo;
        let actions = [];
        console.log('cate', category_uuid, category);

        if (category_uuid) {
            actions.push({
                doIt: e => this.reload(category_uuid),
                icon: <ReplayIcon />,
                onlyIfDirty: !readOnly
            });
        }


        return (
            <Fragment>
                <ProgressOrError progress={fetchingCategory} error={errorCategory} />
                {(!!fetchedCategory || !category_uuid) && (
                    <Fragment>
                        <Form
                            module="grievance"
                            edited_id={category_uuid}
                            edited={category}
                            reset={reset}
                            update={update}
                            title="category.title.bar"
                            // titleParams={{ label: categoryLabel(this.state.category) }}
                            back={back}
                            save={!!save ? this._save : null}
                            canSave={this.canSave}
                            reload={(category_uuid || readOnly)}
                            readOnly={readOnly}
                            overview={overview}
                            Panels={category_uuid ? [EditCategoryPage] : [AddCategoryPage]}
                            onEditedChanged={this.onEditedChanged}
                            actions={actions}
                            openDirty={save}

                        />
                    </Fragment>
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    fetchingCategory: state.grievance.fetchingCategory,
    errorCategory: state.grievance.errorCategory,
    fetchedCategory: state.grievance.fetchedCategory,
    category: state.grievance.category,
    submittingMutation: state.grievance.submittingMutation,
    mutation: state.grievance.mutation,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchCategory, journalize }, dispatch);
};

export default withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CategoryForm)

));