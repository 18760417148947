import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import {
  journalize,
  TextInput,
  FormattedMessage,
} from "@openimis/fe-core";
import { updateCategory, fetchCategory } from "../actions";
import { Save } from "@material-ui/icons";


const styles = (theme) => ({
  paper: theme.paper.paper,
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

const style = {
  background: "white",
  width: "40%",
  margin: "0 auto",
  position: "relative",
  top: "40%",
  padding: "2em",
  borderRadius: "10px",
};

class EditCategoryPage extends Component {
  state = {
    state_edited: {},
    openFileModal: false,
    file: null,
    fileName: "",
  };
  componentDidMount() {
    if (this.props.edited) {
      console.log("props edited", this.props.edited);
      this.setState((state, props) => ({ state_edited: props.edited }));
    }
  }

  componentDidUpdate(prevPops, prevState, snapshort) {
    if (prevState.state_edited !== this.state.state_edited) {
      this.props.onEditedChanged(this.state.state_edited)
      // console.log("Mise à jour : Le state 'value' a changé.");
      // Exécutez une action ici, comme une requête ou une mise à jour.
    }
    if (prevPops.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  save = (e) => {
    this.props.updateCategory(
      this.state.state_edited,
      // `updated ticket ${this.state.state_edited.ticketCode}`
    );
  };

  updateAttribute = (k, v) => {
    this.setState((state) => ({
      state_edited: { ...state.state_edited, [k]: v },
    }));
  };


  render() {
    const {
      intl,
      onEditedChanged,
      classes,
      category,
      edited,
      titletwo = "Category.DescriptionOfEvent",
      titlethree = " Category.Resolution",
      titleParams = { label: "" },
      category_uuid,
      actions,
      save,
    } = this.props;

    const { state_edited } = this.state;
    return (
      <div className={classes.page}>

        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container className={classes.tableTitle}>
                <Grid item xs={12} className={classes.tableTitle}>
                  <Typography>
                    <FormattedMessage module="grievance" id={titletwo} values="" />
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container className={classes.item}>

                <Grid item xs={6} className={classes.item}>
                  <TextInput
                    module="grievance" label="category.categoryTitle"
                    value={state_edited.categoryTitle}
                    onChange={v => this.updateAttribute("categoryTitle", v)}
                    required={false} />
                </Grid>
                <Grid item xs={6} className={classes.item}>
                  <TextInput
                    label="category.slug"
                    value={state_edited.slug}
                    onChange={v => this.updateAttribute("slug", v)}
                    required={false} />
                </Grid>
                <Grid item xs={11} className={classes.item}>
                </Grid>
                {/* <Grid item xs={1} className={classes.item}>
                  <IconButton variant="contained" component="label" color="primary" onClick={this.save}>
                    <Save />
                  </IconButton>
                </Grid> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        {/* <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container className={classes.tableTitle}>
                <Grid item xs={12} className={classes.tableTitle}>
                  <Typography>
                    <FormattedMessage
                      module="grievance"
                      id={titlethree}
                      values={titleParams}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container className={classes.item}>
                <Grid item xs={12} className={classes.item}>
                  <TextInput
                    label="ticket.resolution"
                    value={state_edited.resolution}
                    onChange={(v) => this.updateAttribute("resolution", v)}
                    required={false}
                  />
                </Grid>
                <Grid item xs={11} className={classes.item}></Grid>
                <Grid item xs={1} className={classes.item}>
                  <IconButton
                    variant="contained"
                    component="label"
                    color="primary"
                    onClick={this.save}
                  >
                    <Save />
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid> */}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  submittingMutation: state.grievance.submittingMutation,
  mutation: state.grievance.mutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchCategory, updateCategory, journalize },
    dispatch
  );
};

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(EditCategoryPage)
  )
);

const mstyles = {
  title: {
    fontSize: "1.3em",
    fontWeight: "900",
  },

  button: {
    fontSize: "3em",
    color: "#006273",
    // width: "40%",
    marginTop: "-20px",
    width: "10%",
    cursor: "pointer",
  },
  labels: {
    display: "flex",
    width: "100%",
  },

  label: {
    width: "45%",
  },

  inputs: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  input: {
    width: "40%",
  },
};
