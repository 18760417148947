import React, { Component } from "react";
import _debounce from "lodash/debounce";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import {
    withModulesManager,
    Contributions,
    ControlledField,
    TextInput,
} from "@openimis/fe-core";

const styles = (theme) => ({
    dialogTitle: theme.dialog.title,
    dialogContent: theme.dialog.content,
    form: {
        padding: 0,
    },
    item: {
        padding: theme.spacing(1),
    },
    paperDivider: theme.paper.divider,
});

const COTEGORY_FILTER_CONTRIBUTION_KEY = "category.Filter";

class CategoryFilter extends Component {
    state = {
        showHistory: false,
    };

    debouncedOnChangeFilter = _debounce(
        this.props.onChangeFilters,
        this.props.modulesManager.getConf("fe-grievance", "debounceTime", 800),
    );

    _filterValue = (k) => {
        const { filters } = this.props;
        return !!filters && !!filters[k] ? filters[k].value : null;
    };

    render() {
        const { intl, classes, filters, onChangeFilters } = this.props;
    
        return (
            <Grid container className={classes.form}>
                <ControlledField
                    module="grievance"
                    id="categoryFilter.categoryTitle"
                    field={
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="grievance"
                                label="category.categoryTitle"
                                name="categoryTitle"
                                value={this._filterValue("categoryTitle")}
                                onChange={(v) =>
                                    this.debouncedOnChangeFilter([
                                        {
                                            id: "categoryTitle",
                                            value: v,
                                            filter: `categoryTitle_Istartswith: "${v}"`,
                                        },
                                    ])
                                }
                            />
                        </Grid>
                    }
                />
                <ControlledField
                    module="grievance"
                    id="category.slug"
                    field={
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="grievance"
                                label="category.slug"
                                name="slug"
                                value={this._filterValue("slug")}
                                onChange={(v) =>
                                    this.debouncedOnChangeFilter([
                                        {
                                            id: "slug",
                                            value: v,
                                            filter: `slug_Istartswith: "${v}"`,
                                        },
                                    ])
                                }
                            />
                        </Grid>
                    }
                />
                <ControlledField
                    module="grievance"
                    id="categoryFilter.validFrom"
                    field={
                        <Grid item xs={3} className={classes.item}>
                            <TextInput
                                module="grievance"
                                label="category.validFrom"
                                name="validFrom"
                                value={this._filterValue("validFrom")}
                                onChange={(v) =>
                                    this.debouncedOnChangeFilter([
                                        {
                                            id: "validFrom",
                                            value: v,
                                            filter: `validFrom_Icontains: "${v}"`,
                                        },
                                    ])
                                }
                            />
                        </Grid>
                    }
                />
               
                <Contributions
                    filters={filters}
                    onChangeFilters={onChangeFilters}
                    contributionKey={COTEGORY_FILTER_CONTRIBUTION_KEY}
                />
            </Grid>
        );
    }
}

export default withModulesManager(injectIntl(withTheme(withStyles(styles)(CategoryFilter))));
