import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles, withTheme } from "@material-ui/core/styles";
import ReplayIcon from "@material-ui/icons/Replay";

import {
  coreConfirm,
  Helmet,
  ErrorBoundary,
  Form,
  formatMessageWithValues,
  historyPush,
  journalize,
  parseData,
  ProgressOrError,
  withHistory,
  withModulesManager,
} from "@openimis/fe-core";
import {
  createMedicalGratuite,
  fetchMedicalGratuite,
  fetchMedicalGratuiteMutation,
  newMedicalGratuite,
  clearGratuiteForm,
} from "../actions";
import { RIGHT_MEDICALGRATUITES, GRATUITE_CODE_MAX_LENGTH } from "../constants";
import MedicalGratuiteMasterPanel from "./MedicalGratuiteMasterPanel";

const styles = (theme) => ({
  lockedPage: theme.page.locked,
});

class MedicalGratuiteForm extends Component {
  state = {
    lockNew: false,
    reset: 0,
    medicalGratuite: null,
    newMedicalGratuite: true,
    confirmedAction: null,
  };

  // newMedicalGratuite() {
  //   return ;
  // }

  componentDidMount() {    
    if (this.props.medicalGratuiteId) {
      this.setState(
        (state, props) => ({ medicalGratuiteId: props.medicalGratuiteId }),
        (e) => this.props.fetchMedicalGratuite(this.props.modulesManager, this.props.medicalGratuiteId),
      );
    }
    if (this.props.id) {
      this.setState((state, props) => ({
        medicalGratuite: {
          ...this.newMedicalGratuite(),
          id: props.id,
        },
      }));
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.fetchedMedicalGratuite && !!this.props.fetchedMedicalGratuite) {
      const { medicalGratuite } = this.props;
      this.setState({
        medicalGratuite,
        medicalGratuiteId: medicalGratuite.id,
        lockNew: false,
        newMedicalGratuite: false,
      });
    } else if (prevProps.medicalGratuiteId && !this.props.medicalGratuiteId) {
      this.setState({
        medicalGratuite: this.newMedicalGratuite(),
        newMedicalGratuite: true,
        lockNew: false,
        medicalGratuiteId: null,
      });
    } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
      
      
      this.props.journalize(this.props.mutation);
      this.setState((state, props) => ({
        medicalGratuite: {
          ...state.medicalGratuite,
        },
      }));
    } else if (prevProps.confirmed !== this.props.confirmed && !!this.props.confirmed && !!this.state.confirmedAction) {
      this.state.confirmedAction();
    }
  }

  componentWillUnmount = () => {
    this.props.clearGratuiteForm();
  };

  add = () => {
    this.setState(  
      (state) => ({
        medicalGratuite: this.newMedicalGratuite(),
        newMedicalGratuite: true,
        lockNew: false,
        reset: state.reset + 1,
      }),
      (e) => {
        this.props.add();
        this.forceUpdate();
      },
    );
  };

  reload = () => {
    const { medicalGratuiteId } = this.props.mutation;
    if ( !medicalGratuiteId) {
      this.props.fetchMedicalGratuiteMutation(this.props.modulesManager).then((res) => {
        console.log('reload',res.payload.data.mutationLogs);
        
        const mutationLogs = parseData(res.payload.data.mutationLogs);
        if (
          mutationLogs &&
          mutationLogs[0] &&
          mutationLogs[0].medicalGratuites &&
          mutationLogs[0].medicalGratuites[0] &&
          mutationLogs[0].medicalGratuites[0].coreUser
        ) {
          const { id } = parseData(res.payload.data.mutationLogs)[0].users[0].coreUser;
          if (id) {
            historyPush(this.props.modulesManager, this.props.history, "medical.medicalGratuiteOverview", [id]);
          }
        }
      });
    } else {
      this.props.fetchMedicalGratuite(this.props.modulesManager, medicalGratuiteId);
    }
  };

  canSave = () =>
    this.state.medicalGratuite &&
    this.state.medicalGratuite.code &&
    this.state.medicalGratuite.code.length <= GRATUITE_CODE_MAX_LENGTH &&
    this.state.medicalGratuite.name &&
    this.props.isGratuiteValid;

  save = (medicalGratuite) => { 
    console.log('donnesave',medicalGratuite);
        
    this.setState(
      { lockNew: !medicalGratuite.id }, // avoid duplicates
      (e) => this.props.save(medicalGratuite),
    );
  };

  onEditedChanged = (medicalGratuite) => {
    this.setState({ medicalGratuite, newMedicalGratuite: false });
  };

  onActionToConfirm = (title, message, confirmedAction) => {
    this.setState({ confirmedAction }, this.props.coreConfirm(title, message));
  };

  render() {
    const {
      classes,
      rights,
      medicalGratuiteId,
      fetchingMedicalGratuite,
      fetchedMedicalGratuite,
      errorMedicalGratuite,
      overview = false,
      readOnly = false,
      add,
      save,
      back,
    } = this.props;
    
    const { medicalGratuite, reset, lockNew } = this.state;
    // if (!rights.includes(RIGHT_MEDICALGRATUITES)) return null;
    const actions = [
      {
        doIt: this.reload,
        icon: <ReplayIcon />,
        onlyIfDirty: !readOnly,
      },
    ];
    return (
      <div className={lockNew ? classes.lockedPage : null}>
        <Helmet title={formatMessageWithValues(this.props.intl, "medical.gratuite", "MedicalGratuiteOverview.title")} />
        <ProgressOrError progress={fetchingMedicalGratuite} error={errorMedicalGratuite} />
        <ErrorBoundary>
          {((!!fetchedMedicalGratuite && !!medicalGratuite && medicalGratuite.id === medicalGratuiteId) ||
            !medicalGratuiteId) && (
            <Form
              module="medicalGratuite"
              title={
                this.state.newMedicalGratuite
                  ? "medical.gratuite.MedicalGratuiteOverview.newTitle"
                  : "medical.gratuite.MedicalGratuiteOverview.title"
              }
              edited_id={medicalGratuiteId}
              edited={medicalGratuite}
              reset={reset}
              back={back}
              add={!!add && !this.state.newMedicalGratuite ? this.add : null}
              readOnly={readOnly || lockNew || (!!medicalGratuite && !!medicalGratuite.validityTo)}
              actions={actions}
              overview={overview}
              HeadPanel={MedicalGratuiteMasterPanel}
              medicalGratuite={medicalGratuite}
              onEditedChanged={this.onEditedChanged}
              canSave={this.canSave}
              save={save ? this.save : null}
              openDirty={save}
              onActionToConfirm={this.onActionToConfirm}
            />
          )}
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  fetchingMedicalGratuite: state.medical.fetchingMedicalGratuite,
  errorMedicalGratuite: state.medical.errorMedicalGratuite,
  fetchedMedicalGratuite: state.medical.fetchedMedicalGratuite,
  submittingMutation: state.medical.submittingMutation,
  mutation: state.medical.mutation,
  medicalGratuite: state.medical.medicalGratuite,
  confirmed: state.core.confirmed,
  isGratuiteValid: state.medical?.validationFields?.medicalGratuite?.isValid,
  state,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearGratuiteForm,
      fetchMedicalGratuite,
      newMedicalGratuite,
      createMedicalGratuite,
      fetchMedicalGratuiteMutation,
      journalize,
      coreConfirm,
    },
    dispatch,
  );

export default withHistory(
  withModulesManager(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(MedicalGratuiteForm)))),
  ),
);
