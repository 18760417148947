import React, { Component } from "react";
import { ConstantBasedPicker } from "@openimis/fe-core";

import { PAYMENT_MODE } from "../constants";

class PayementMode extends Component {

    render() {
        return <ConstantBasedPicker
            module="contribution"
            label="policy.payMode"
            constants={PAYMENT_MODE}
            {...this.props}
        />
    }
}

export default PayementMode;