import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { decodeId } from "@openimis/fe-core";
import { useDispatch } from "react-redux";
import { PovertyActive } from "../actions";

const Detail = ({ domaine, family, infos, onClose, isOpen, modulesManager }) => {
  const [openSubmit, setOpen] = useState(false);
  const dispatch = useDispatch();

  const valider = (e) => {
    e.preventDefault();
    dispatch(PovertyActive(modulesManager, decodeId(family), "Indigence activée, famille de "+ infos.lastName + " " + infos.otherNames + " " + (infos.chfId)));
    setOpen(false);
    onClose();
  };
  const soumettre = () => {
    setOpen(true);
  };
  const onCancel = () => {
    setOpen(false);
  };
  const totalScore =
    domaine && Array.isArray(domaine)
      ? domaine.reduce((sumDomaine, dom) => {
          return dom.indicateurs && Array.isArray(dom.indicateurs)
            ? sumDomaine +
                dom.indicateurs.reduce((sumIndicateur, ind) => {
                  return ind.familyIndicateurs && Array.isArray(ind.familyIndicateurs)
                    ? sumIndicateur +
                        ind.familyIndicateurs.reduce((sumFamily, item) => {
                          const score = parseFloat(item.score); // Conversion en nombre
                          return sumFamily + (!isNaN(score) ? score : 0); // Ajouter uniquement si valide
                        }, 0)
                    : sumIndicateur;
                }, 0)
            : sumDomaine;
        }, 0)
      : null;

  return (
    <div>
      <Dialog open={openSubmit} onCancel={onCancel}>
        <DialogTitle>Êtes-vous sûr?</DialogTitle>
        <DialogContent>
          De vouloir activer l'indigence de la famille de {infos.lastName + " " + infos.otherNames} ({infos.chfId}){" "}
        </DialogContent>
        <DialogActions>
          <Button color="red" onClick={onCancel} variant="contained">
            Non
          </Button>
          <Button color="primary" onClick={valider} variant="contained">
            Oui
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpen} fullWidth maxWidth="lg">
        <DialogTitle>
          Situation de l'indigence  de la famille de {infos.lastName + " " + infos.otherNames} ({infos.chfId}){" "}
          <h1>Total Score: {totalScore} point(s)</h1>
        </DialogTitle>
        <form>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Indicateurs</TableCell>
                  <TableCell>Critères</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>Scores</TableCell>
                  <TableCell>Observations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {domaine &&
                  domaine.length > 0 &&
                  domaine.map((d, iidx) => (
                    <React.Fragment key={iidx}>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <h2>
                            {iidx + 1}. {d.libelle}
                          </h2>
                        </TableCell>
                      </TableRow>
                      {d.indicateurs &&
                        d.indicateurs.length > 0 &&
                        d.indicateurs.map((i, ii) => (
                          <TableRow key={ii}>
                            <TableCell>{ii + 1}</TableCell>
                            <TableCell>{i.libelle}</TableCell>
                            <TableCell>
                              {i.sousIndicateurs.map((s, ss) => (
                                <Table key={ss}>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>{s.libelle}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              ))}
                            </TableCell>
                            <TableCell>
                              {i.sousIndicateurs.map((s, ss) => (
                                <Table key={ss}>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>{s.point}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              ))}
                            </TableCell>
                            <TableCell>{i.familyIndicateurs[0].score}</TableCell>
                            <TableCell>{i.familyIndicateurs[0].observation}</TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            {domaine && domaine.length > 0 ? (
              <p>
                Cliquez sur valider pour activer l'indigence  de la famille de {infos.lastName + " " + infos.otherNames}{" "}
                ({infos.chfId}) ou Annuler?
              </p>
            ) : (
              <p>Aucun domaine disponible</p>
            )}
            <Button color="red" onClick={onClose} variant="contained">
              Annuler
            </Button>
            {domaine && domaine.length > 0 && (
              <Button color="primary" onClick={soumettre} variant="contained">
                Valider
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>

      {/* <Button color="primary" variant="contained" onClick={valider}>
        Valider
      </Button> */}
    </div>
  );
};

export default Detail;
