import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { TextInput, decodeId } from "@openimis/fe-core";
import { useDispatch } from "react-redux";
import { DomaineCreate } from "../actions";

const DialogPoverty = ({ domaines, family, infos, onClose, isOpen, modulesManager }) => {
  const [form, setFormValues] = useState([]);
  const [scores, setScores] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  // Fonction pour gérer les changements de saisie
  const handleInputChange = (indicateurId, fieldName, value, minPoints, maxPoints) => {
    // Contrôle pour s'assurer que le score est compris entre minPoints et maxPoints
    if (fieldName === "score" && (value < minPoints || value > maxPoints)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [indicateurId]: `Le score doit être compris entre ${minPoints} et ${maxPoints}`,
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [indicateurId]: null }));
    }

    // Mettre à jour les valeurs du formulaire
    setFormValues((prevValues) => {
      const indicateurIndex = prevValues.findIndex((item) => item.indicateurId === indicateurId);

      if (indicateurIndex !== -1) {
        const updatedValues = [...prevValues];
        updatedValues[indicateurIndex][fieldName] = value;
        return updatedValues;
      }

      return [
        ...prevValues,
        {
          family: decodeId(family),
          indicateurId,
          score: fieldName === "score" ? value : "",
          observation: fieldName === "observation" ? value : "",
        },
      ];
    });
  };

  useEffect(() => {
    if (domaines && domaines.length > 0) {
      const allScoresFilled = domaines.every((d) =>
        d.indicateurs?.every((i) =>
          form.some((f) => f.indicateurId === decodeId(i.id) && f.score !== "" && !errors[decodeId(i.id)]),
        ),
      );
      setIsValid(allScoresFilled);
    }
  }, [form, domaines, errors]);

  const valider = (e) => {
    e.preventDefault();
    dispatch(DomaineCreate(modulesManager, form, "Enquete Validée, famille de " + infos.lastName + " " + infos.otherNames + " " + (infos.chfId)));
    onClose();
  };

  return (
    <div>
      <Dialog open={isOpen} fullWidth onCancel={onClose} maxWidth="lg">
        <DialogTitle>
          Situation de l'indigence de la famille de {infos.lastName + " " + infos.otherNames} ({infos.chfId})
        </DialogTitle>
        <form>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Indicateurs</TableCell>
                  <TableCell>Critères</TableCell>
                  <TableCell>Points</TableCell>
                  <TableCell>Scores</TableCell>
                  <TableCell>Observations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {domaines &&
                  domaines.length > 0 &&
                  domaines.map((d, iidx) => (
                    <React.Fragment key={iidx}>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <h2>
                            {iidx + 1}. {d.libelle}
                          </h2>
                        </TableCell>
                      </TableRow>
                      {d.indicateurs &&
                        d.indicateurs.length > 0 &&
                        d.indicateurs.map((i, ii) => {
                          // Calculer le minimum et le maximum des points des sous-indicateurs
                          const pointsArray = i.sousIndicateurs.map((si) => si.point);
                          const minPoints = Math.min(...pointsArray);
                          const maxPoints = Math.max(...pointsArray);

                          return (
                            <TableRow key={ii}>
                              <TableCell>{ii + 1}</TableCell>
                              <TableCell>{i.libelle}</TableCell>
                              <TableCell>
                                {i.sousIndicateurs.map((s, ss) => (
                                  <Table key={ss}>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>{s.libelle}</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                ))}
                              </TableCell>
                              <TableCell>
                                {i.sousIndicateurs.map((s, ss) => (
                                  <Table key={ss}>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>{s.point}</TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                ))}
                              </TableCell>
                              <TableCell>
                                {/* Score pour l'indicateur */}
                                <TextInput
                                  module="insuree"
                                  required
                                  label="Score"
                                  type="number"
                                  error={!!errors[decodeId(i.id)]}
                                  helperText={errors[decodeId(i.id)] || ""}
                                  onChange={(value) =>
                                    handleInputChange(decodeId(i.id), "score", value, minPoints, maxPoints)
                                  }
                                />
                              </TableCell>
                              <TableCell>
                                {/* Observation pour l'indicateur */}
                                <TextInput
                                  module="insuree"
                                  label="Observation"
                                  onChange={(value) => handleInputChange(decodeId(i.id), "observation", value)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button color="red" onClick={onClose} variant="contained">
              Annuler
            </Button>
            <Button color="primary" onClick={valider} disabled={!isValid} variant="contained">
              Valider
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogPoverty;
