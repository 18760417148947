import React, { useState, useCallback, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import {
  withModulesManager,
  formatMessage,
  FormattedMessage,
  formatMessageWithValues,
  Searcher,
  formatDateFromISO,
  coreConfirm,
  journalize,
  withHistory,
  historyPush,
  downloadExport,
  PublishedComponent,
} from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchBills, deleteBill, fetchBillsExport, downloadFacture, generateReportFS } from "../actions";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TableChartIcon from '@material-ui/icons/TableChart';
import {
  DEFAULT_PAGE_SIZE,
  ROWS_PER_PAGE_OPTIONS,
  EMPTY_STRING,
  RIGHT_BILL_UPDATE,
  RIGHT_BILL_DELETE,
  STATUS,
} from "../constants";
import BillFilter from "./BillFilter";
import InvoiceStatusPicker from "../pickers/InvoiceStatusPicker";
import { getSubjectAndThirdpartyTypePicker } from "../util/subject-and-thirdparty-picker";
import {
  IconButton, Tooltip, Button, DialogContent, DialogContentText,
  Dialog,
  DialogActions,
  DialogTitle, Grid
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
const BillSearcher = ({
  intl,
  modulesManager,
  history,
  rights,
  coreConfirm,
  confirmed,
  journalize,
  submittingMutation,
  mutation,
  fetchBills,
  fetchBillsExport,
  deleteBill,
  fetchingBills,
  fetchedBills,
  errorBills,
  bills,
  billsPageInfo,
  billsTotalCount,
  billsExport,
  billsExportPageInfo,
  billsExportTotalCount,
  errorBillsExport,
  actions,
  actionsContributionKey,
  classes,
}) => {
  const [billToDelete, setBillToDelete] = useState(null);
  const [openFactureDialog, setopenFactureDialog] = useState(false);
  const [format, setFormat] = useState(null);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [monthStr, setMonthStr] = useState(null);
  const [Sanitaire, setSanitaire] = useState(false);
  const [deletedBillUuids, setDeletedBillUuids] = useState([]);
  const [failedExport, setFailedExport] = useState(false)
  const prevSubmittingMutationRef = useRef();

  useEffect(() => billToDelete && openConfirmDialog(), [billToDelete]);

  useEffect(() => {
    if (billToDelete && confirmed) {
      confirmedAction();
      setDeletedBillUuids([...deletedBillUuids, billToDelete.id]);
    }
    billToDelete && confirmed !== null && setBillToDelete(null);
  }, [confirmed]);

  useEffect(() => {
    setFailedExport(true)
  }, [errorBillsExport])

  useEffect(() => {
    if (billsExport) {
      downloadExport(billsExport, 'bill_export.csv')();
    }
  }, [billsExport])

  useEffect(() => {
    prevSubmittingMutationRef.current && !submittingMutation && journalize(mutation);
  }, [submittingMutation]);

  useEffect(() => {
    prevSubmittingMutationRef.current = submittingMutation;
  });

  const confirmedAction = useCallback(
    () =>
      deleteBill(
        billToDelete,
        formatMessageWithValues(intl, "bill", "delete.mutationLabel", {
          code: billToDelete.code,
        }),
      ),
    [billToDelete],
  );

  const openConfirmDialog = useCallback(
    () =>
      coreConfirm(
        formatMessageWithValues(intl, "bill", "delete.confirm.title", {
          code: billToDelete.code,
        }),
        formatMessage(intl, "bill", "delete.confirm.message"),
      ),
    [billToDelete],
  );

  const fetch = (params) => fetchBills(params);

  const headers = () => {
    const headers = [
      "bill.subject",
      "bill.thirdparty",
      "bill.code",
      "bill.dateBill",
      "bill.amountTotalI",
      "bill.amountTotalAMD",
      "bill.status.label",
    ];
    if (rights.includes(RIGHT_BILL_UPDATE)) {
      headers.push("emptyLabel");
    }
    return headers;
  };
  const isGenerateDisabled = !year || !month;
  const FactureDateDialog = ({ open, onClose, format }) => (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle >Choisir l'année et mois pour Télécharger la facture</DialogTitle>
      <DialogContent>
        <DialogContentText >
          <Grid container >
            <Grid xs={1} ></Grid>
            <Grid xs={3} >
              <PublishedComponent
                pubRef="core.YearPicker"
                module="claim_batch"
                label="year"
                min={min}
                max={max}
                withNull={false}
                value={year}
                required={true}
                onChange={e => setYear(e)}
              />
            </Grid>
            <Grid xs={1} ></Grid>
            <Grid xs={3} >
              <PublishedComponent
                pubRef="core.MonthPicker"
                module="claim_batch"
                label="month"
                value={month}
                withNull={false}
                required={true}
                onChange={(v, s) => { setMonth(v), setMonthStr(s) }}
              />
            </Grid>
            {/* <Grid xs={1} ></Grid>
            <Grid xs={3} >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={Sanitaire}
                    onChange={e => setSanitaire(e)}
                  />
                }
                label="Par Formation Sanitaire"
              />
            </Grid> */}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'end' }}>

        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button onClick={() => { handleGenerateReport(format), setopenFactureDialog(false) }} color="primary" disabled={isGenerateDisabled}>
          Télécharger
        </Button>
      </DialogActions>
    </Dialog>
  );
  const min = new Date().getFullYear() - 7;
  const max = min + 9;
  const itemFormatters = () => {

    const formatters = [
      (bill) => getSubjectAndThirdpartyTypePicker(modulesManager, bill.subjectTypeName, bill.subject),
      (bill) => getSubjectAndThirdpartyTypePicker(modulesManager, bill.thirdpartyTypeName, bill.thirdparty),
      (bill) => bill.code,
      (bill) =>
        !!bill.dateBill ? formatDateFromISO(modulesManager, intl, bill.dateBill) : EMPTY_STRING,
      (bill) => getSubjectAndThirdpartyTypePicker(modulesManager, bill.thirdpartyTypeName, bill.thirdparty).props.value.level == 'H' ? bill.amountTotal * 20 / 100 : bill.amountTotal * 10 / 100,
      (bill) => getSubjectAndThirdpartyTypePicker(modulesManager, bill.thirdpartyTypeName, bill.thirdparty).props.value.level == 'H' ? bill.amountTotal * 80 / 100 : bill.amountTotal * 90 / 100,
      (bill) => <InvoiceStatusPicker value={bill?.status} readOnly />,
    ];
    if (rights.includes(RIGHT_BILL_UPDATE)) {
      formatters.push((bill) => (
        console.log('ttttt', bill, bill.thirdpartyTypeName, bill.thirdparty, getSubjectAndThirdpartyTypePicker(modulesManager, bill.thirdpartyTypeName, bill.thirdparty).props.value.level),
        <Tooltip title={formatMessage(intl, "invoice", "editButtonTooltip")}>
          <IconButton
            href={billUpdatePageUrl(bill)}
            onClick={(e) => e.stopPropagation() && onDoubleClick(bill)}
            disabled={deletedBillUuids.includes(bill.id)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ));
    }
    if (rights.includes(RIGHT_BILL_DELETE)) {
      formatters.push((bill) => (
        <Tooltip title={formatMessage(intl, "invoice", "deleteButtonTooltip")}>
          <IconButton
            onClick={() => onDelete(bill)}
            disabled={bill?.status === STATUS.PAYED || deletedBillUuids.includes(bill.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ));
    }
    return formatters;
  };

  const rowIdentifier = (bill) => bill.id;

  const sorts = () => [
    ["subjectType", true],
    ["thirdpartyType", true],
    ["code", true],
    ["dateBill", true],
    ["amountTotal", true],
    ["status", true],
  ];

  const billUpdatePageUrl = (bill) => modulesManager.getRef("bill.route.bill") + "/" + bill?.id;

  const onDoubleClick = (bill, newTab = false) =>
    rights.includes(RIGHT_BILL_UPDATE) &&
    !deletedBillUuids.includes(bill.id) &&
    historyPush(modulesManager, history, "bill.route.bill", [bill?.id], newTab);

  const onDelete = (bill) => setBillToDelete(bill);

  const isRowDisabled = (_, bill) => deletedBillUuids.includes(bill.id);

  const defaultFilters = () => ({
    isDeleted: {
      value: false,
      filter: "isDeleted: false",
    },
  });
  const handleGenerateReport = (fileFormat) => {
    const params = {
      fileFormat: fileFormat,
      moisStr: monthStr,
      mois: month,
      annee: year,
      fs: Sanitaire,
    }
    if (Sanitaire) {
      generateReportFS(params, intl)
    } else {
      downloadFacture(params, intl);
    }
    setSanitaire(false)
  }
  const exportFields = () => ({
    'id': 'ID',
    'userCreated.username': "User",
    'amount_total': "AmountTotal",
    "subjectTypeName": "Subject",
    'thirdpartyTypeName': "SenderType",
    'thirdparty': ""
  })

  return (
    <div>
      <FactureDateDialog
        open={openFactureDialog}
        format={format}
        onClose={() => setopenFactureDialog(false)}
      />
      <Grid container justifyContent="flex-end">
        <Grid xs={2}>
          <Button startIcon={<PictureAsPdfIcon />} variant="contained" onClick={() => { setFormat("pdf"), setopenFactureDialog(true) }}>
            <FormattedMessage module="invoice" id="bill.downloadPDF" />
          </Button>

        </Grid>

        <Grid xs={2}>
          <Button startIcon={<TableChartIcon />} variant="contained" onClick={() => { setFormat("xlsx"), setopenFactureDialog(true) }}>
            <FormattedMessage module="invoice" id="bill.downloadXLSX" />
          </Button>
        </Grid>
      </Grid>
      <Searcher


        module="bill"
        FilterPane={BillFilter}
        fetch={fetch}
        items={bills}
        itemsPageInfo={billsPageInfo}
        fetchingItems={fetchingBills}
        fetchedItems={fetchedBills}
        errorItems={errorBills}
        tableTitle={formatMessageWithValues(intl, "bill", "bills.searcherResultsTitle", {
          billsTotalCount,
        })}
        headers={headers}
        itemFormatters={itemFormatters}
        sorts={sorts}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        defaultOrderBy="code"
        rowIdentifier={rowIdentifier}
        onDoubleClick={onDoubleClick}
        defaultFilters={defaultFilters()}
        rowDisabled={isRowDisabled}
        rowLocked={isRowDisabled}
        actions={actions}
        actionsContributionKey={actionsContributionKey}
        withSelection="multiple"
        selectionMessage={"bill.selection.count"}
        exportable={true}
        exportFetch={fetchBillsExport}
        exportFields={[
          'id', 'userCreated.username', 'amount_total', 'subjectType', 'subjectId', 'thirdpartyType',
          'thirdpartyId', 'code', 'status', 'dateBill'
        ]}
        exportFieldsColumns={{
          'id': 'ID',
          'userCreated.username': "User",
          'amount_total': "AmountTotal",
          "subjectType": "Subject Type",
          "subject": "subject",
          'thirdpartyType': "SenderType",
          'thirdparty': "Sender",
          'code': 'code',
          'dateBill': 'Date Bill',
          'status': 'Status',
        }}
      />

      {failedExport && (
        <Dialog fullWidth maxWidth="sm">
          <DialogTitle>{errorBillsExport}</DialogTitle>
          <DialogActions>
            <Button onClick={setFailedExport(false)} variant="contained">
              {formatMessage(intl, "invoice", "ok")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetchingBills: state.invoice.fetchingBills,
  fetchedBills: state.invoice.fetchedBills,
  errorBills: state.invoice.errorBills,
  bills: state.invoice.bills,
  billsPageInfo: state.invoice.billsPageInfo,
  billsTotalCount: state.invoice.billsTotalCount,
  confirmed: state.core.confirmed,
  submittingMutation: state.invoice.submittingMutation,
  mutation: state.invoice.mutation,

  fetchingBillsExport: state.invoice.fetchingBillsExport,
  fetchedBillsExport: state.invoice.fetchedBillsExport,
  billsExport: state.invoice.billsExport,
  billsExportPageInfo: state.invoice.billsExportPageInfo,
  errorBillsExport: state.invoice.errorBillsExport,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      downloadFacture,
      generateReportFS,
      fetchBills,
      deleteBill,
      fetchBillsExport,
      downloadExport,
      coreConfirm,
      journalize,
    },
    dispatch
  );
};

export default withHistory(
  withModulesManager(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BillSearcher))),
);
