import React from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
  ControlledField,
  PublishedComponent,
  FormPanel,
  TextInput,
  withModulesManager,
  decodeId,
} from "@openimis/fe-core";
import {
  Grid,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Box
} from "@material-ui/core";
import { connect } from "react-redux";

const styles = (theme) => ({
  item: theme.paper.item,
});

class HealthEvaluationMasterPanel extends FormPanel {
  state = {
    form: {
      year: null,
      trimestre: null,
      donnees: [],
    },
  };
  constructor(props) {
    super(props);
    this.codeMaxLength = props.modulesManager.getConf("fe-location", "healthFacilityForm.codeMaxLength", 8);
    this.accCodeMaxLength = props.modulesManager.getConf("fe-location", "healthFacilityForm.accCodeMaxLength", 25);
    this.accCodeMandatory = props.modulesManager.getConf("fe-location", "healthFacilityForm.accCodeMandatory", false);
  }
  updateFormField = (field, value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [field]: value,
      },
    }));
  };
  handleInputChange = (id, field, value) => {
    this.setState(
      (prevState) => {
        const updatedDonnees = [...prevState.form.donnees];
        const index = updatedDonnees.findIndex((d) => d.id === id);
  
        if (index > -1) {
          updatedDonnees[index][field] = value;
        } else {
          updatedDonnees.push({ id, [field]: value });
        }
  
        return {
          form: {
            ...prevState.form,
            esa: decodeId(this.props.edited_id),
            donnees: updatedDonnees,
          },
        };
      },
      () => {
        // Callback appelé après la mise à jour de l'état
        this.updateAttribute("form", this.state.form);
      }
    );
  };
  
  shouldValidate = (inputValue) => {
    const { savedHFCode } = this.props;
    const shouldValidate = inputValue !== savedHFCode;
    return shouldValidate;
  };

  render() {
    const {
      classes,
      edited,
      reset,
      readOnly = false,
      isHFCodeValid,
      isHFCodeValidating,
      HFCodeValidationError,
      healthFacilityEvaluation,
      onEditedChanged,
      edited_id
    } = this.props;
    // console.log(edited_id);
    
    const min = new Date().getFullYear() - 7;
    const max = min + 9;
    return (
      <>
        <Grid container>
          <ControlledField
            module="location"
            id="HealthFacility.region"
            field={
              <Grid item xs={6} className={classes.item}>
                <PublishedComponent
                  pubRef="core.YearPicker"
                  module="claim_batch"
                  label="year"
                  min={min}
                  max={max}
                  withNull={false}
                  value={this.state.form.year}
                  required={true}
                  onChange={(v) => this.updateFormField("year", v)}
                />
              </Grid>
            }
          />
          <ControlledField
            module="location"
            id="HealthFacility.region"
            field={
              <Grid item xs={6} className={classes.item}>
                <PublishedComponent
                  pubRef="location.TrimestrePicker"
                  nullLabel="empty"
                  reset={reset}
                  readOnly={readOnly}
                  required={true}
                  onChange={(v) => this.updateFormField("trimestre", v)}
                />
              </Grid>
            }
          />
        </Grid>
        <Box padding="10px"></Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Indicateur</TableCell>
              <TableCell>Points</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Observations</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {healthFacilityEvaluation &&
              healthFacilityEvaluation.length > 0 &&
              healthFacilityEvaluation.map((d, iidx) => (
                <React.Fragment key={iidx}>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <h2>
                        {iidx + 1}. {d.libelle}
                      </h2>
                    </TableCell>
                  </TableRow>
                  {d.indicators &&
                    d.indicators.length > 0 &&
                    d.indicators.map((i, ii) => (
                      <TableRow>
                        <TableCell>{ii + 1} </TableCell>
                        <TableCell>{i.libelle}</TableCell>
                        <TableCell>{i.point}</TableCell>
                        <TableCell>
                          {/* Score pour l'indicateur */}
                          <TextInput
                            onChange={(value) => this.handleInputChange(decodeId(i.id), "score", value)}
                            module="insuree"
                            required
                            label="Score"
                            type="number"
                          />
                        </TableCell>
                        <TableCell>
                          {/* Observation pour l'indicateur */}
                          <TextInput
                            module="insuree"
                            label="Observation"
                            onChange={(value) => this.handleInputChange(decodeId(i.id), "observation", value)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </>
    );
  }
}



export default withModulesManager(connect()(withTheme(withStyles(styles)(HealthEvaluationMasterPanel))));
