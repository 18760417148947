import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { IconButton, Tooltip } from "@material-ui/core";
import { Tab as TabIcon, Delete as DeleteIcon, Visibility as EyeIcon } from "@material-ui/icons";
import {
  withModulesManager,
  formatMessageWithValues,
  formatMessage,
  Searcher,
  journalize,
  formatDateFromISO,
} from "@openimis/fe-core";

import { fetchMedicalGratuitesSummaries, deleteMedicalGratuite } from "../actions";
import { RIGHT_MEDICALSERVICES_DELETE } from "../constants";
import DeleteMedicalGratuiteDialog from "./DeleteMedicalGratuiteDialog";
import InfoMedicalGratuiteDialog from "./InfoMedicalGratuiteDialog";
import MedicalGratuiteFilter from "./MedicalGratuiteFilter";

class MedicalGratuiteSearcher extends Component {
  state = {
    deleteGratuite: null,
    infoGratuite: null,
    reset: 0,
    params: {},
  };

  constructor(props) {
    super(props);
    this.locationLevels = 4;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      console.log('prev',prevProps.submittingMutation);
      this.props.journalize(this.props.mutation);
      this.setState({ reset: this.state.reset + 1 });
    }
  }

  fetch = (params) => {
    this.setState({ params });
    this.props.fetchMedicalGratuitesSummaries(this.props.modulesManager, params);
  };

  rowIdentifier = (r) => r.id;

  filtersToQueryParams = (state) => {
    const prms = Object.keys(state.filters)
      .filter((contrib) => !!state.filters[contrib].filter)
      .map((contrib) => state.filters[contrib].filter);
    if (!state.beforeCursor && !state.afterCursor) {
      prms.push(`first: ${state.pageSize}`);
    }
    if (state.afterCursor) {
      prms.push(`after: "${state.afterCursor}"`);
      prms.push(`first: ${state.pageSize}`);
    }
    if (state.beforeCursor) {
      prms.push(`before: "${state.beforeCursor}"`);
      prms.push(`last: ${state.pageSize}`);
    }
    // if (state.orderBy) {
    //   prms.push(`orderBy: ["${state.orderBy}"]`);
    // }
    return prms;
  };

  headers = () => {
    const h = [
      "medical.gratuite.code",
      "medical.gratuite.name",
      "medical.gratuite.ageMin",
      "medical.gratuite.ageMax",
      "medical.gratuite.genre",
      "medical.gratuite.validFrom",
    ];
    return h;
  };

  sorts = () => [
    ["code", true],
    ["name", true],
    ["ageMin", false],
    ["ageMax", false],
    ["genre", false],
    ["validityFrom", false],
  ];

  deleteGratuite = () => {
    console.log('dell');
    const gratuite = this.state.deleteGratuite;
    this.setState({ deleteGratuite: null }, async (e) => {
      await this.props.deleteMedicalGratuite(
        this.props.modulesManager,
        gratuite,
        formatMessage(this.props.intl, "medical.gratuite", "deleteDialog.title"),
      );
      this.fetch(this.state.params);
    });
  };

  confirmDelete = (deletedGratuite) => {
    console.log('cost');
    this.setState({ deleteGratuite: deletedGratuite });
  };

  deleteAction = (i) => {
    console.log('dest');
    
    return !!i.validityTo ? null : (
      <Tooltip title={formatMessage(this.props.intl, "medical.gratuite", "deleteGratuite.tooltip")}>
        <IconButton onClick={() => this.confirmDelete(i)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  };

  confirmInfo = (infoGra) => {
    console.log('info');
    this.setState({ infoGratuite: infoGra });
  };

  infoAction = (i) => {
    console.log('infoact', i);
    return !!i.validityTo ? null : (
      <Tooltip title={formatMessage(this.props.intl, "medical.gratuite", "Info Soins et Produits")}>
        <IconButton onClick={() => this.confirmInfo(i)}>
          <EyeIcon/>
        </IconButton>
      </Tooltip>
    );
  };

  itemFormatters = () => {    
    const formatters = [
      (ms) => ms.code,
      (ms) => ms.name,
      (ms) => ms.ageMin,
      (ms) => ms.ageMax,
      (ms) => ms.genre?.altLanguage,
      (ms) => formatDateFromISO(this.props.modulesManager, this.props.intl, ms.validityFrom),
      (ms) => (
        <Tooltip title={formatMessage(this.props.intl, "medical.gratuite", "openNewTab")}>
          <IconButton onClick={(e) => this.props.onDoubleClick(ms, true)}>
            <TabIcon />
          </IconButton>
        </Tooltip>
      ),
    ];
    formatters.push(this.infoAction);
    formatters.push(this.deleteAction);
    // if (this.props.rights.includes(RIGHT_MEDICALSERVICES_DELETE)) {
    //   formatters.push(this.deleteAction);
    // }
    return formatters;
  };

  rowDisabled = (selection, i) => !!i.validityTo;

  // rowLocked = (selection, i) => !!i.clientMutationId;

  render() {
    const {
      intl,
      medicalGratuites,
      medicalGratuitesPageInfo,
      fetchingMedicalGratuites,
      fetchedMedicalGratuites,
      errorMedicalGratuites,
      cacheFiltersKey,
      onDoubleClick,
    } = this.props;
    const count = medicalGratuitesPageInfo.totalCount;
    console.log('ms',medicalGratuites);
    return (
      <>
       
        
        <DeleteMedicalGratuiteDialog
          medicalGratuite={this.state.deleteGratuite}
          onConfirm={this.deleteGratuite}
          onCancel={(e) => this.setState({ deleteGratuite: null })}
        />
        <InfoMedicalGratuiteDialog
          medicalGratuite={this.state.infoGratuite}
          onConfirm={this.deleteGratuite}
          onCancel={(e) => this.setState({ infoGratuite: null })}
        />
        <Searcher
          module="medicalGratuite"
          cacheFiltersKey={cacheFiltersKey}
          FilterPane={MedicalGratuiteFilter}
          items={medicalGratuites}
          itemsPageInfo={medicalGratuitesPageInfo}
          fetchingItems={fetchingMedicalGratuites}          
          fetchedItems={fetchedMedicalGratuites}
          errorItems={errorMedicalGratuites}
          tableTitle={formatMessageWithValues(intl, "medical.gratuite", "medicalGratuiteSummaries", {
            count,
          })}
          fetch={this.fetch}
          rowIdentifier={this.rowIdentifier}
          filtersToQueryParams={this.filtersToQueryParams}
          defaultOrderBy="code"
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          sorts={this.sorts}
          rowDisabled={this.rowDisabled}
          // rowLocked={this.rowLocked}
          onDoubleClick={(c) => onDoubleClick(c)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.core?.user?.i_user?.rights ?? [],
  medicalGratuites: state.medical.medicalGratuitesSummaries,
  medicalGratuitesPageInfo: state.medical.medicalGratuitesPageInfo,
  fetchingMedicalGratuites: state.medical.fetchingMedicalGratuitesSummaries,
  fetchedMedicalGratuites: state.medical.fetchedMedicalGratuitesSummaries,
  errorMedicalGratuites: state.medical.errorMedicalGratuitesSummaries,
  submittingMutation: state.medical.submittingMutation,
  mutation: state.medical.mutation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMedicalGratuitesSummaries, deleteMedicalGratuite, journalize }, dispatch);

export default withModulesManager(connect(mapStateToProps, mapDispatchToProps)(injectIntl(MedicalGratuiteSearcher)));
