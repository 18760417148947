import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush,
} from "@openimis/fe-core";
import CategoryForm from "../components/CategoryForm";
import { updateCategory, createCategory } from "../actions";
import { RIGHT_CATEGORY_ADD, RIGHT_CATEGORY_EDIT } from "../constants";

const styles = theme => ({
    page: theme.page,
});

class CategoryPage extends Component {

    add = () => {
        historyPush(this.props.modulesManager, this.props.history, "grievance.route.category")
    }

    save = (category) => {
        if (!category.uuid) {
            this.props.createCategory(category, `Categorie crée avec succès`)
        } else {
            this.props.updateCategory(
                category,
                `Categorie ${category.uuid} a été mis à jour`
            );
        }
    }

    render() {
        const { classes, modulesManager, history, rights, category_uuid, overview } = this.props;
        console.log('category_uuid', category_uuid);

        if (!rights.includes(RIGHT_CATEGORY_EDIT)) return null;
        return (
            <div className={classes.page}>
                <CategoryForm
                    overview={overview}
                    category_uuid={category_uuid}
                    back={e => historyPush(modulesManager, history, "grievance.route.categorys")}
                    add={rights.includes(RIGHT_CATEGORY_ADD) ? this.add : null}
                    save={rights.includes(RIGHT_CATEGORY_EDIT) ? this.save : null}
                />
            </div>
        )
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    category_uuid: props.match.params.category_uuid,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createCategory, updateCategory }, dispatch);
};

export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(CategoryPage))
    ))));