import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { IconButton, Tooltip } from "@material-ui/core";
import { Tab as TabIcon, Delete as DeleteIcon, Visibility as EyeIcon } from "@material-ui/icons";
import {
  withModulesManager,
  formatMessageWithValues,
  formatMessage,
  Searcher,
  journalize,
  formatDateFromISO,
} from "@openimis/fe-core";

import { fetchMedicalDiagnosticsSummaries, deleteMedicalDiagnostic } from "../actions";
import { RIGHT_MEDICALSERVICES_DELETE } from "../constants";
import DeleteMedicalDiagnosticDialog from "./DeleteMedicalDiagnosticDialog";
import MedicalDiagnosticFilter from "./MedicalDiagnosticFilter";

class MedicalDiagnosticSearcher extends Component {
  state = {
    deleteDiagnostic: null,
    reset: 0,
    params: {},
  };

  constructor(props) {
    super(props);
    this.locationLevels = 4;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      console.log('prev', prevProps.submittingMutation);
      this.props.journalize(this.props.mutation);
      this.setState({ reset: this.state.reset + 1 });
    }
  }

  fetch = (params) => {
    this.setState({ params });
    this.props.fetchMedicalDiagnosticsSummaries(this.props.modulesManager, params);
  };

  rowIdentifier = (r) => r.id;

  filtersToQueryParams = (state) => {
    const prms = Object.keys(state.filters)
      .filter((contrib) => !!state.filters[contrib].filter)
      .map((contrib) => state.filters[contrib].filter);
    if (!state.beforeCursor && !state.afterCursor) {
      prms.push(`first: ${state.pageSize}`);
    }
    if (state.afterCursor) {
      prms.push(`after: "${state.afterCursor}"`);
      prms.push(`first: ${state.pageSize}`);
    }
    if (state.beforeCursor) {
      prms.push(`before: "${state.beforeCursor}"`);
      prms.push(`last: ${state.pageSize}`);
    }
    // if (state.orderBy) {
    //   prms.push(`orderBy: ["${state.orderBy}"]`);
    // }
    return prms;
  };

  headers = () => {
    const h = [
      "medical.diagnostic.code",
      "medical.diagnostic.name",
      "medical.diagnostic.niveau",
      "medical.diagnostic.validFrom",
    ];
    return h;
  };

  sorts = () => [
    ["code", true],
    ["name", true],
    ["niveau", false],
    ["validityFrom", false],
  ];

  deleteDiagnostic = () => {
    console.log('dell');
    const diagnostic = this.state.deleteDiagnostic;
    this.setState({ deleteDiagnostic: null }, async (e) => {
      await this.props.deleteMedicalDiagnostic(
        this.props.modulesManager,
        diagnostic,
        formatMessage(this.props.intl, "medical.diagnostic", "deleteDialog.title"),
      );
      this.fetch(this.state.params);
    });
  };

  confirmDelete = (deletedDiagnostic) => {
    console.log('cost');
    this.setState({ deleteDiagnostic: deletedDiagnostic });
  };

  deleteAction = (i) => {
    console.log('dest');

    return !!i.validityTo ? null : (
      <Tooltip title={formatMessage(this.props.intl, "medical.diagnostic", "deleteDiagnostic.tooltip")}>
        <IconButton onClick={() => this.confirmDelete(i)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );
  };

  itemFormatters = () => {
    const formatters = [
      (ms) => ms.code,
      (ms) => ms.name,
      (ms) => ms.niveau ? formatMessage(this.props.intl, "location.healthFacilityLevel", ms.niveau) : null,
      (ms) => formatDateFromISO(this.props.modulesManager, this.props.intl, ms.validityFrom),
      (ms) => (
        <Tooltip title={formatMessage(this.props.intl, "medical.diagnostic", "openNewTab")}>
          <IconButton onClick={(e) => this.props.onDoubleClick(ms, true)}>
            <TabIcon />
          </IconButton>
        </Tooltip>
      ),
    ];
    formatters.push(this.deleteAction);
    // if (this.props.rights.includes(RIGHT_MEDICALSERVICES_DELETE)) {
    //   formatters.push(this.deleteAction);
    // }
    return formatters;
  };

  rowDisabled = (selection, i) => !!i.validityTo;

  // rowLocked = (selection, i) => !!i.clientMutationId;

  render() {
    const {
      intl,
      medicalDiagnostics,
      medicalDiagnosticsPageInfo,
      fetchingMedicalDiagnostics,
      fetchedMedicalDiagnostics,
      errorMedicalDiagnostics,
      cacheFiltersKey,
      onDoubleClick,
    } = this.props;
    const count = medicalDiagnosticsPageInfo.totalCount;
    console.log('ms', medicalDiagnostics);
    return (
      <>


        <DeleteMedicalDiagnosticDialog
          medicalDiagnostic={this.state.deleteDiagnostic}
          onConfirm={this.deleteDiagnostic}
          onCancel={(e) => this.setState({ deleteDiagnostic: null })}
        />
        <Searcher
          module="medicalDiagnostic"
          cacheFiltersKey={cacheFiltersKey}
          FilterPane={MedicalDiagnosticFilter}
          items={medicalDiagnostics}
          itemsPageInfo={medicalDiagnosticsPageInfo}
          fetchingItems={fetchingMedicalDiagnostics}
          fetchedItems={fetchedMedicalDiagnostics}
          errorItems={errorMedicalDiagnostics}
          tableTitle={formatMessageWithValues(intl, "medical.diagnostic", "medicalDiagnosticSummaries", {
            count,
          })}
          fetch={this.fetch}
          rowIdentifier={this.rowIdentifier}
          filtersToQueryParams={this.filtersToQueryParams}
          defaultOrderBy="code"
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          sorts={this.sorts}
          rowDisabled={this.rowDisabled}
          // rowLocked={this.rowLocked}
          onDoubleClick={(c) => onDoubleClick(c)}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: state.core?.user?.i_user?.rights ?? [],
  medicalDiagnostics: state.medical.medicalDiagnosticsSummaries,
  medicalDiagnosticsPageInfo: state.medical.medicalDiagnosticsPageInfo,
  fetchingMedicalDiagnostics: state.medical.fetchingMedicalDiagnosticsSummaries,
  fetchedMedicalDiagnostics: state.medical.fetchedMedicalDiagnosticsSummaries,
  errorMedicalDiagnostics: state.medical.errorMedicalDiagnosticsSummaries,
  submittingMutation: state.medical.submittingMutation,
  mutation: state.medical.mutation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchMedicalDiagnosticsSummaries, deleteMedicalDiagnostic, journalize }, dispatch);

export default withModulesManager(connect(mapStateToProps, mapDispatchToProps)(injectIntl(MedicalDiagnosticSearcher)));
