import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@openimis/fe-core";
import { fetchEnrolmentInsurees } from "../../actions";

const formatSuggestion = (p) => {
  if (!p) return "?";
  return [p.chfId, p.lastName, p.otherNames].filter(Boolean).join(" ");
};

const EnrolmentInsureePicker = (props) => {
  const {
    onChange,
    modulesManager,
    readOnly = false,
    required = false,
    withLabel = true,
    value,
    label,
    filterOptions,
    filterSelectedOptions,
    placeholder,
    multiple = true,
  } = props;

  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState(null);

  const [endCursor, setEndCursor] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(true);

  const isLoading = useSelector((state) => state.admin.enrolmentInsurees.isFetching);
  const options = useSelector((state) => state.admin.enrolmentInsurees.items);
  useEffect(() => {
    dispatch(
      fetchEnrolmentInsurees(modulesManager, {
        searchString,
        first: searchString ? undefined : 10,
        // after: endCursor || null, // Utilise la vraie valeur du curseur ici
      }),
    )
      
  }, [searchString]);
  const handleFetchSuccess = (data) => {
    if (data.insuree.pageInfo) {
      setEndCursor(data.insuree.pageInfo.endCursor); // Met à jour le curseur
      setHasNextPage(data.insuree.pageInfo.hasNextPage); // Met à jour hasNextPage
    }
  };

  return (
    <Autocomplete
      multiple={multiple}
      required={required}
      placeholder="Recherche..."
      label="Choisir le(s) Assurés"
      withLabel={withLabel}
      readOnly={false}
      options={options}
      isLoading={isLoading}
      value={value}
      getOptionLabel={formatSuggestion}
      onChange={onChange}
      filterOptions={filterOptions}
      filterSelectedOptions={filterSelectedOptions}
      onInputChange={setSearchString}
    />
  );
};

export default EnrolmentInsureePicker;
