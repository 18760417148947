import React, { Component, Fragment, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {} from "@material-ui/core";
import HealthEvaluationMasterPanel from "../components/HealthEvaluationMasterPanel";
// import ReplayIcon from "@material-ui/icons/Replay";
import { Form, withModulesManager, journalize, formatMessageWithValues, Helmet } from "@openimis/fe-core";
import { fetchHealthFacilityEvaluation, clearHealthFacility } from "../actions";
import { withStyles, withTheme } from "@material-ui/core/styles";

const styles = (theme) => ({
  page: theme.page,
  paper: theme.paper.paper,
  title: theme.paper.title,
  item: theme.paper.item,
});

const HF_FORM_CONTRIBUTION_KEY = "location.HealthFacility";

class HealthFacilityEvaluationForm extends Component {
  state = {
    lockNew: false,
    reset: 0,
    update: 0,
    healthFacility_id: null,
    healthFacility: this._newHealthFacility(),
    newHealthFacility: true,
  };

  constructor(props) {
    super(props);
  }
  _newHealthFacility() {
    let hf = {};
    return hf;
  }
  _save = () => {
    this.setState((e) => this.props.save(this.state.healthFacility));
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.fetchedHealthFacility !== this.props.fetchedHealthFacility &&
      !!this.props.fetchedHealthFacility &&
      !!this.props.healthFacility
    ) {
      this.setState((state, props) => ({
        healthFacility: { ...props.healthFacility, parentLocation: props.healthFacility.location.parent },
        healthFacility_id: props.healthFacility.id,
        lockNew: false,
        newHealthFacility: false,
      }));
    } else if (prevState.healthFacility_id !== this.state.healthFacility_id) {
      this.props.fetchHealthFacilityEvaluation(this.props.modulesManager, this.state.healthFacility_id, null);
    } else if (prevProps.healthFacility_id && !this.props.healthFacility_id) {
      this.setState({ healthFacility: this._newHealthFacility(), lockNew: false, healthFacility_id: null });
    } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
      this.setState((state) => ({ reset: state.reset + 1 }));
    }
  }

  componentDidMount() {
    this.props.fetchHealthFacilityEvaluation(null, this.props.healthFacility_id);
    // console.log(this.props.healthFacility_id);
    if (this.props.healthFacility_id) {
      this.setState((state, props) => ({ healthFacility_id: props.healthFacility_id }));
    }
  }
  canSave = () => {
    if (!this.state.healthFacility?.form?.year) return false;
    if (!this.state.healthFacility?.form?.trimestre) return false;
    return true;
  };
  onEditedChanged = (healthFacility) => {
    this.setState({ healthFacility });
  };
  reload = () => {};

  render() {
    const { healthFacilityEvaluation, classes, back, add, save } = this.props;
    const { reset, update, healthFacility_id } = this.state;
    let readOnly = false;
    let actions = [];
    return (
      <Fragment>
        <Helmet
          title={formatMessageWithValues(this.props.intl, "location", "healthFacility.edit.page.title", {
            code: 14,
          })}
        />

        <Fragment>
          <Form
            module="location"
            edited_id={healthFacility_id}
            edited={null}
            reset={reset}
            update={update}
            title="Evaluation"
            back={back}
            classes={classes}
            add={!!add ? this._add : null}
            save={!!save ? this._save : null}
            canSave={this.canSave}
            reload={(healthFacility_id || readOnly) && this.reload}
            readOnly={readOnly}
            HeadPanel={HealthEvaluationMasterPanel}
            healthFacilityEvaluation={healthFacilityEvaluation}
            onEditedChanged={this.onEditedChanged}
            actions={actions}
            contributedPanelsKey={HF_FORM_CONTRIBUTION_KEY}
            openDirty={true}
          />
        </Fragment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  //   userHealthFacilityFullPath: !!state.loc ? state.loc.userHealthFacilityFullPath : null,
  healthFacilityEvaluation: state.loc.healthFacilityEvaluation,
  //   fetchingHealthFacility: state.loc.fetchingHealthFacility,
  //   fetchedHealthFacility: state.loc.fetchedHealthFacility,
  //   errorHealthFacility: state.loc.errorHealthFacility,
  //   submittingMutation: state.loc.submittingMutation,
  //   mutation: state.loc.mutation,
  //   isHFCodeValid: state.loc.validationFields?.HFCode?.isValid,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchHealthFacilityEvaluation, clearHealthFacility, journalize }, dispatch);
};

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(injectIntl(HealthFacilityEvaluationForm)))),
);
