import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import {
  withModulesManager,
  formatMessage,
  FormattedMessage,
  formatMessageWithValues,
  Searcher,
  formatDateFromISO,
  coreConfirm,
  journalize,
  withHistory,
  historyPush,
  downloadExport,
  PublishedComponent,
} from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchInvoices, deleteInvoice,downloadFactureInvoice } from "../actions";
import {
  DEFAULT_PAGE_SIZE,
  ROWS_PER_PAGE_OPTIONS,
  EMPTY_STRING,
  RIGHT_INVOICE_UPDATE,
  RIGHT_INVOICE_DELETE,
  STATUS,
} from "../constants";
import InvoiceFilter from "./InvoiceFilter";
// import InvoiceStatusPicker from "../pickers/InvoiceStatusPicker";
// import { getSubjectAndThirdpartyTypePicker } from "../util/subject-and-thirdparty-picker";
// import { IconButton, Tooltip } from "@material-ui/core";
import {
  IconButton, Tooltip, Button, DialogContent, DialogContentText,
  Dialog,
  DialogActions,
  DialogTitle, Grid
} from "@material-ui/core";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import TableChartIcon from '@material-ui/icons/TableChart';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const InvoiceSearcher = ({
  intl,
  modulesManager,
  history,
  rights,
  coreConfirm,
  confirmed,
  journalize,
  submittingMutation,
  mutation,
  fetchInvoices,
  deleteInvoice,
  fetchingInvoices,
  fetchedInvoices,
  errorInvoices,
  invoices,
  invoicesPageInfo,
  invoicesTotalCount,
}) => {
  const [invoiceToDelete, setInvoiceToDelete] = useState(null);
  const [deletedInvoiceUuids, setDeletedInvoiceUuids] = useState([]);
  const prevSubmittingMutationRef = useRef();
  const [openFactureDialog, setopenFactureDialog] = useState(false);
  const [format, setFormat] = useState(null);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [monthStr, setMonthStr] = useState(null);
  const [Sanitaire, setSanitaire] = useState(false);
  useEffect(() => invoiceToDelete && openDeleteInvoiceConfirmDialog(), [invoiceToDelete]);

  useEffect(() => {
    if (invoiceToDelete && confirmed) {
      deleteInvoice(
        invoiceToDelete,
        formatMessageWithValues(intl, "invoice", "invoice.delete.mutationLabel", {
          code: invoiceToDelete.code,
        }),
      );
      setDeletedInvoiceUuids([...deletedInvoiceUuids, invoiceToDelete.id]);
    }
    invoiceToDelete && confirmed !== null && setInvoiceToDelete(null);
  }, [confirmed]);

  useEffect(() => {
    prevSubmittingMutationRef.current && !submittingMutation && journalize(mutation);
  }, [submittingMutation]);

  useEffect(() => {
    prevSubmittingMutationRef.current = submittingMutation;
  });

  const openDeleteInvoiceConfirmDialog = () =>
    coreConfirm(
      formatMessageWithValues(intl, "invoice", "invoice.delete.confirm.title", {
        code: invoiceToDelete.code,
      }),
      formatMessage(intl, "invoice", "invoice.delete.confirm.message"),
    );

  const fetch = (params) => fetchInvoices(params);

  const headers = () => {
    const headers = [
      "invoice.code",
      "invoice.insuree",
      "invoice.policy",
      "invoice.dateInvoice",
      "invoice.Montant.Photo",
      "invoice.Montant.menage",
      "invoice.Montant.bailleur",
      "invoice.Montant.total",
    ];
    if (rights.includes(RIGHT_INVOICE_UPDATE)) {
      headers.push("emptyLabel");
    }
    return headers;
  };
  const isGenerateDisabled = !year || !month;
  const FactureDateDialog = ({ open, onClose, format }) => (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle >Choisir l'année et mois pour Télécharger la facture</DialogTitle>
      <DialogContent>
        <DialogContentText >
          <Grid container >
            <Grid xs={1} ></Grid>
            <Grid xs={3} >
              <PublishedComponent
                pubRef="core.YearPicker"
                module="claim_batch"
                label="year"
                min={min}
                max={max}
                withNull={false}
                value={year}
                required={true}
                onChange={e => setYear(e)}
              />
            </Grid>
            <Grid xs={1} ></Grid>
            <Grid xs={3} >
              <PublishedComponent
                pubRef="core.MonthPicker"
                module="claim_batch"
                label="month"
                value={month}
                withNull={false}
                required={true}
                onChange={(v, s) => { setMonth(v), setMonthStr(s) }}
              />
            </Grid>
            {/* <Grid xs={1} ></Grid>
              <Grid xs={3} >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={Sanitaire}
                      onChange={e => setSanitaire(e)}
                    />
                  }
                  label="Par Formation Sanitaire"
                />
              </Grid> */}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'end' }}>

        <Button onClick={onClose} color="primary">
          Annuler
        </Button>
        <Button onClick={() => { handleGenerateReport(format), setopenFactureDialog(false) }} color="primary" disabled={isGenerateDisabled}>
          Télécharger
        </Button>
      </DialogActions>
    </Dialog>
  );
  const min = new Date().getFullYear() - 7;
  const max = min + 9;
  const handleGenerateReport = (fileFormat) => {
    const params = {
      fileFormat: fileFormat,
      moisStr: monthStr,
      mois: month,
      annee: year,
      fs: Sanitaire,
    }    
   downloadFactureInvoice(params, intl);
  }
  const itemFormatters = () => {
    const formatters = [
      (invoice) => invoice.code,
      (invoice) => invoice.policyId.family.headInsuree.lastName + " " + invoice.policyId.family.headInsuree.otherNames,
      (invoice) => invoice.policyId.product.name,
      (invoice) => !!invoice.dateInvoice ? formatDateFromISO(modulesManager, intl, invoice.dateInvoice) : EMPTY_STRING,
      (invoice) => invoice.montantPhoto,
      (invoice) => invoice.montantMenage,
      (invoice) => invoice.montantBailleur,
      (invoice) => (
        (parseFloat(invoice.montantBailleur) || 0) +
        (parseFloat(invoice.montantMenage) || 0) +
        (parseFloat(invoice.montantPhoto) || 0)
      ).toFixed(2),
    ];
    if (rights.includes(RIGHT_INVOICE_UPDATE)) {
      formatters.push((invoice) => (
        <Tooltip title={formatMessage(intl, "invoice", "editButtonTooltip")}>
          <IconButton
            href={invoiceUpdatePageUrl(invoice)}
            onClick={(e) => e.stopPropagation() && onDoubleClick(invoice)}
            disabled={deletedInvoiceUuids.includes(invoice.id)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ));
    }
    if (rights.includes(RIGHT_INVOICE_DELETE)) {
      formatters.push((invoice) => (
        <Tooltip title={formatMessage(intl, "invoice", "deleteButtonTooltip")}>
          <IconButton
            onClick={() => onDelete(invoice)}
            disabled={invoice?.status === STATUS.PAYED || deletedInvoiceUuids.includes(invoice.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ));
    }
    return formatters;
  };

  const rowIdentifier = (invoice) => invoice.id;

  const sorts = () => [
    ["subjectType", true],
    ["thirdpartyType", true],
    ["code", true],
    ["dateInvoice", true],
    ["amountTotal", true],
    ["status", true],
  ];

  const invoiceUpdatePageUrl = (invoice) => modulesManager.getRef("invoice.route.invoice") + "/" + invoice?.id;

  const onDoubleClick = (invoice, newTab = false) =>
    rights.includes(RIGHT_INVOICE_UPDATE) &&
    !deletedInvoiceUuids.includes(invoice.id) &&
    historyPush(modulesManager, history, "invoice.route.invoice", [invoice?.id], newTab);

  const onDelete = (invoice) => setInvoiceToDelete(invoice);

  const isRowDisabled = (_, invoice) => deletedInvoiceUuids.includes(invoice.id);

  const defaultFilters = () => ({
    // isDeleted: {
    //   value: false,
    //   filter: "isDeleted: false",
    // },
  });
  console.log('rrr', invoices);

  return (
    <div>
      <FactureDateDialog
        open={openFactureDialog}
        format={format}
        onClose={() => setopenFactureDialog(false)}
      />
      <Grid container justifyContent="flex-end">
        <Grid xs={2}>
          <Button startIcon={<PictureAsPdfIcon />} variant="contained" onClick={() => { setFormat("pdf"), setopenFactureDialog(true) }}>
            <FormattedMessage module="invoice" id="bill.downloadPDF" />
          </Button>

        </Grid>

        <Grid xs={2}>
          <Button startIcon={<TableChartIcon />} variant="contained" onClick={() => { setFormat("xlsx"), setopenFactureDialog(true) }}>
            <FormattedMessage module="invoice" id="bill.downloadXLSX" />
          </Button>
        </Grid>
      </Grid>
      <Searcher
        module="invoice"
        FilterPane={InvoiceFilter}
        fetch={fetch}
        items={invoices}
        itemsPageInfo={invoicesPageInfo}
        fetchingItems={fetchingInvoices}
        fetchedItems={fetchedInvoices}
        errorItems={errorInvoices}
        tableTitle={formatMessageWithValues(intl, "invoice", "invoices.searcherResultsTitle", {
          invoicesTotalCount,
        })}
        headers={headers}
        itemFormatters={itemFormatters}
        sorts={sorts}
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        defaultOrderBy="code"
        rowIdentifier={rowIdentifier}
        onDoubleClick={onDoubleClick}
        defaultFilters={defaultFilters()}
        rowDisabled={isRowDisabled}
        rowLocked={isRowDisabled}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  fetchingInvoices: state.invoice.fetchingInvoices,
  fetchedInvoices: state.invoice.fetchedInvoices,
  errorInvoices: state.invoice.errorInvoices,
  invoices: state.invoice.invoices,
  invoicesPageInfo: state.invoice.invoicesPageInfo,
  invoicesTotalCount: state.invoice.invoicesTotalCount,
  confirmed: state.core.confirmed,
  submittingMutation: state.invoice.submittingMutation,
  mutation: state.invoice.mutation,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchInvoices,
      deleteInvoice,
      coreConfirm,
      journalize,
    },
    dispatch,
  );

export default withHistory(
  withModulesManager(injectIntl(connect(mapStateToProps, mapDispatchToProps)(InvoiceSearcher))),
);
