import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { ControlledField, PublishedComponent,decodeId } from "@openimis/fe-core";

const Export = ({ isOpen, onClose, health_id }) => {
  const [formValues, setFormValues] = useState({});
  const valider = (e) => {
    const query = new URLSearchParams();
    query.set('health_id',decodeId(health_id))
    for (const [key, value] of Object.entries(formValues)) {
      query.set(key, value);
    }
    window.open(`/api/location/export/?${query.toString()}`, "_blank");
  };
  const min = new Date().getFullYear() - 7;
  const max = min + 9;
  return (
    <>
      <Dialog open={isOpen} onCancel={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Exportation</DialogTitle>
        <DialogContent>
          <Grid container>
            <ControlledField
              module="location"
              id="HealthFacility.region"
              field={
                <Grid item xs={12}>
                  <PublishedComponent
                    pubRef="core.YearPicker"
                    module="claim_batch"
                    label="year"
                    min={min}
                    max={max}
                    withNull={false}
                    value={formValues.year}
                    onChange={(year) => setFormValues({ ...formValues, year })}
                  />
                </Grid>
              }
            />
            <ControlledField
              module="location"
              id="HealthFacility.region"
              field={
                <Grid item xs={12}>
                  <PublishedComponent
                    pubRef="location.TrimestrePicker"
                    nullLabel="empty"
                    onChange={(trimestre) => setFormValues({ ...formValues, trimestre })}
                  />
                </Grid>
              }
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button onClick={valider}>Exporter</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Export;
