import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import {
  AmountInput,
  ErrorBoundary,
  FormPanel,
  PublishedComponent,
  TextInput,
  ValidatedTextInput,
  withHistory,
  withModulesManager,
} from "@openimis/fe-core";
import { medicalGratuitesValidationCheck, medicalGratuitesValidationClear, medicalGratuitesSetValid } from "../actions";
import { GRATUITE_CODE_MAX_LENGTH } from "../constants";

const styles = (theme) => ({
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class MedicalGratuiteMasterPanel extends FormPanel {
  shouldValidate = (inputValue) => {
    const { savedGratuiteCode } = this.props;    
    const shouldValidate = inputValue !== savedGratuiteCode;
    return shouldValidate;
  }
  render() {
    const { classes, edited, readOnly, isGratuiteValid, isGratuiteValidating, gratuiteValidationError} = this.props;
    return (
      <ErrorBoundary>
        <Grid container className={classes.item}>
          <Grid item xs={4} className={classes.item}>
            <ValidatedTextInput
              action={medicalGratuitesValidationCheck}
              clearAction={medicalGratuitesValidationClear}
              setValidAction={medicalGratuitesSetValid}
              itemQueryIdentifier="gratuiteCode"
              isValid={isGratuiteValid}
              isValidating={isGratuiteValidating}
              validationError={gratuiteValidationError}
              shouldValidate={this.shouldValidate}
              codeTakenLabel="medical.codeTaken"
              onChange={(code) => this.updateAttribute("code", code)}
              inputProps={{ maxLength: GRATUITE_CODE_MAX_LENGTH }}
              required={true}
              module="admin"
              label="medical.gratuite.code"
              readOnly={readOnly}
              value={edited ? edited.code : ""}
            />
          </Grid>
          <Grid item xs={8} className={classes.item}>
            <TextInput
              module="admin"
              label="medical.gratuite.name"
              required
              readOnly={readOnly}
              value={edited && edited.name ? edited.name : ""}
              onChange={(name) => this.updateAttributes({ name })}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.item}>
        <Grid item xs={4} className={classes.item}>
            <TextInput
              module="admin"
              label="medical.gratuite.ageMin"
              readOnly={readOnly}
              value={edited && edited.ageMin ? edited.ageMin : ""}
              onChange={(ageMin) => this.updateAttributes({ ageMin })}
            />
          </Grid>
          <Grid item xs={4} className={classes.item}>
            <TextInput
              module="admin"
              label="medical.gratuite.ageMax"
              readOnly={readOnly}
              value={edited && edited.ageMax ? edited.ageMax : ""}
              onChange={(ageMax) => this.updateAttributes({ ageMax })}
            />
          </Grid>
          <Grid item xs={4} className={classes.item}>
              <PublishedComponent
                pubRef="medical.GenrePicker"
                value={!!edited && !!edited.genre ? edited.genre : null}
                readOnly={readOnly}
                withNull={false}
                onChange={(p) => this.updateAttributes({"genre":p} )}
              />
          </Grid>
        </Grid>
        {/* <Grid container className={classes.item}>
        
      
        </Grid> */}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
  isGratuiteValid: state.medical?.validationFields?.medicalGratuite?.isValid,
  isGratuiteValidating: state.medical?.validationFields?.medicalGratuite?.isValidating,
  gratuiteValidationError: state.medical?.validationFields?.medicalGratuite?.validationError,
  savedGratuiteCode: state.medical?.medicalGratuite?.code,
});

export default injectIntl(
  withModulesManager(withHistory(connect(mapStateToProps)(withTheme(withStyles(styles)(MedicalGratuiteMasterPanel))))),
);
